import { Dispatch, ReactChild, ReactNode } from 'react'
import { CheckBoxField } from '../../../../views/check-field'
import { SelectField } from '../../../../views/select'
import { TextField } from '../../../../views/text-field'
import { RichTextField } from '../../../../views/rich-text'
import { DateField } from '../../../../views/date-field'
// import { makeFileInputProgram } from './file'
// import { makeReferenceInputProgram } from './reference'
import styled from 'styled-components'
import { Change } from 'center/compiled/util/raj'
import { absurd } from '../../../../util/exhaustiveness'
import { makeFileInputProgram } from './file'
import { Remote } from '../../remote'

const Hint = styled.span`
  font-size: 0.9em;
  font-weight: normal;
  color: #333;
`

export type InputTypeInfo = {
  key: string
  kind:
    | { type: 'checkbox' }
    | { type: 'date' }
    | { type: 'password' }
    | {
        type: 'text'
        isMultipleLines: boolean
      }
    | { type: 'rich_text' }
    | { type: 'select'; values: { key: string; title: string }[] }
    | { type: 'enum'; values: { key: string; title: string; value: string }[] }
    | {
        type: 'number'
        minimum: number | undefined
        maximum: number | undefined
        step: number | undefined
      }
    | {
        type: 'file'
        allowedFileTypes: string[]
      }
  title: ReactChild
  optional?: boolean | { label: string; description: string }

  disabled: boolean
  description: string | undefined
}

type InputProgramOptions = {
  actionKey: string
  typeInfo: InputTypeInfo
  fieldInfo: {
    value: unknown
  }
  remote: Remote
}

export function makeInputProgram({
  actionKey,
  typeInfo,
  fieldInfo,
  remote,
}: InputProgramOptions): InputProgram<any, any> {
  if (typeInfo.optional) {
    const [label, description] =
      typeof typeInfo.optional === 'object'
        ? [typeInfo.optional.label, typeInfo.optional.description]
        : ['Optional', 'This field is optional.']

    typeInfo = {
      ...typeInfo,
      title: (
        <>
          {typeInfo.title} <Hint title={description}>{label}</Hint>
        </>
      ),
    }
  }

  const type = typeInfo.kind.type
  switch (type) {
    case 'file': {
      if (
        !(typeof fieldInfo.value === 'string' || fieldInfo.value === undefined)
      ) {
        throw new Error('expected file field value to be string or undefined')
      }

      return makeFileInputProgram({
        actionKey,
        inputKey: typeInfo.key,
        initialValue: fieldInfo.value ? { url: fieldInfo.value } : undefined,
        remote,
        typeInfo: typeInfo as any,
      })
    }
    // case 'reference':
    //   return makeReferenceInputProgram({
    //     referenceInput: inputTypeInfo,
    //     fieldInfo,
    //     dataOptions,
    //   })
    default: {
      // Most inputs do not need the power of a program
      // Good rule of thumb is to upgrade once effects are needed
      return makeSimpleInputProgram({
        typeInfo: typeInfo,
        initialValue: fieldInfo.value,
      })
    }
  }
}

export type PreSubmitStatus =
  | { type: 'error'; message: string }
  | { type: 'loading' }
  | { type: 'success' }

export type InputProgram<Msg, Model> = {
  init: Change<Msg, Model>
  update: (msg: Msg, model: Model) => Change<Msg, Model>
  view: (model: Model, formView: FormView, dispatch: Dispatch<Msg>) => ReactNode
  done?: (model: Model) => void
  getSubmissionValue: (model: Model) => unknown

  getPreSubmitStatus?: (model: Model) => PreSubmitStatus
  performPreSubmit?: (model: Model) => Change<Msg, Model> | undefined
}

type Msg = { type: 'set_value'; value: unknown }
type Model = {
  value: unknown
}

export type FormView = {
  isEnabled: boolean
  autoFocus: boolean
}

type SimpleInputOptions = {
  typeInfo: InputTypeInfo
  initialValue: unknown
}

function makeSimpleInputProgram({
  typeInfo,
  initialValue,
}: SimpleInputOptions): InputProgram<Msg, Model> {
  const init: Change<Msg, Model> = [
    {
      value: initialValue,
    },
  ]

  function update(msg: Msg, model: Model): Change<Msg, Model> {
    switch (msg.type) {
      case 'set_value': {
        return [{ ...model, value: msg.value }]
      }
      default:
        return absurd(msg.type)
    }
  }

  const getSubmissionValue = (model: Model) => {
    const value = model.value

    switch (typeInfo.kind.type) {
      case 'date': {
        if (value === undefined) {
          return
        }

        if (typeof value !== 'number') {
          throw new Error('need a number')
        }

        const date = new Date(value)
        return date.toISOString()
      }
      case 'enum': {
        return { type: value }
      }
      default:
        return value
    }
  }

  const view = (
    model: Model,
    { isEnabled, autoFocus }: FormView,
    dispatch: Dispatch<Msg>
  ) => {
    const { kind } = typeInfo

    switch (kind.type) {
      case 'checkbox': {
        if (typeof model.value !== 'boolean') {
          throw new Error('need a bool')
        }

        return (
          <CheckBoxField
            {...{
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              isEnabled,
              autoFocus,
              isRequired: !typeInfo.optional,
            }}
          />
        )
      }
      case 'date': {
        if (model.value !== undefined && typeof model.value !== 'number') {
          throw new Error('need a number')
        }

        return (
          <DateField
            {...{
              title: typeInfo.title,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              autoFocus,
              isEnabled,
              isRequired: !typeInfo.optional,
            }}
          />
        )
      }
      case 'password': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        return (
          <TextField
            {...{
              type: 'password',
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              isEnabled,
              autoFocus,
              isRequired: !typeInfo.optional,
            }}
          />
        )
      }
      case 'text': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        return (
          <TextField
            {...{
              type: 'text',
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              isEnabled,
              autoFocus,
              isRequired: !typeInfo.optional,
              isMultipleLines: kind.isMultipleLines,
            }}
          />
        )
      }
      case 'enum': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        const options = kind.values.map((option) => ({
          title: option.title,
          value: option.key,
        }))

        return (
          <SelectField
            {...{
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              options,
              isEnabled,
              isRequired: !!!typeInfo.optional,
            }}
          />
        )
      }
      case 'select': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        const options = kind.values.map((option) => ({
          title: option.title,
          value: option.key,
        }))

        return (
          <SelectField
            {...{
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              options,
              isEnabled,
              isRequired: !!!typeInfo.optional,
            }}
          />
        )
      }
      case 'rich_text': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        return (
          <RichTextField
            {...{
              title: typeInfo.title,
              description: typeInfo.description,
              editorKey: typeInfo.key,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              isEnabled,
            }}
          />
        )
      }
      case 'number': {
        if (typeof model.value !== 'string') {
          throw new Error('need a string')
        }

        const { step, minimum, maximum } = kind

        return (
          <TextField
            {...{
              type: 'number',
              title: typeInfo.title,
              description: typeInfo.description,
              value: model.value,
              onValue(value) {
                dispatch({ type: 'set_value', value })
              },
              isEnabled,
              autoFocus,
              isRequired: !!!typeInfo.optional,
              inputMode:
                step === undefined
                  ? undefined
                  : step % 1 === 0 && step >= 1
                  ? 'numeric'
                  : 'decimal',
              min: minimum,
              max: maximum,
              step: step,
            }}
          />
        )
      }
      case 'file': {
        throw new Error('handled as a complex input program')
      }
      default:
        absurd(kind)
    }
  }

  return {
    init,
    update,
    view,
    getSubmissionValue,
  }
}
