// import { ImageField } from '../../../../views/image-field'
import {
  makeFileUploadSubscription,
  Msg as FileUploadMsg,
} from '../../file-upload'
import { FileField } from '../../../../views/file-field'
import { Change, Dispatch, Effect } from 'center/compiled/util/raj'
import { batchEffects, mapEffect } from 'center/compiled/util/raj-compose'
import { absurd } from '../../../../util/exhaustiveness'
import { FormView, InputProgram, InputTypeInfo, PreSubmitStatus } from '.'
import { Remote, RemoteResult } from '../../remote'
import {
  InputFileUploadRequest,
  InputFileUploadResult,
} from 'center/src/ui-bridge'

type ReadResult =
  | { type: 'error'; error: ProgressEvent<FileReader> }
  | { type: 'value'; value: FileReader['result'] }

const readLocalImageDataURL =
  (file: Blob): Effect<ReadResult> =>
  (dispatch) => {
    const reader = new window.FileReader()
    reader.onload = () => {
      dispatch({ type: 'value', value: reader.result })
    }
    reader.onerror = (error) => {
      dispatch({ type: 'error', error })
    }
    reader.readAsDataURL(file)
  }

type FileInputOptions = {
  actionKey: string
  inputKey: string
  initialValue: { url: string } | undefined
  typeInfo: Model['typeInfo']
  remote: Remote
}

// We don't seem to need to know what this is
type EditorState = {} | undefined

type Model = {
  isImageField: boolean
  typeInfo: InputTypeInfo & { kind: { type: 'file' } }

  isReading: boolean
  readError: ProgressEvent<FileReader> | undefined

  localImageURL: string | undefined
  savedImageURL: string | undefined
  uploadRequestURL: string | undefined
  persistedImageURL: string | undefined
  isRequestingUpload: boolean
  requestError: { message: string } | undefined

  editorState: EditorState

  isUploading: boolean
  uploadError: { message: string } | undefined
  uploadStats: { uploadedByteCount: number; totalByteCount: number } | undefined
  uploadCancel: Effect<never> | undefined
  isUploaded: boolean

  fileList: FileList | undefined
}

type Msg =
  | { type: 'set_editor_state'; editorState: EditorState }
  | { type: 'files_added'; fileList: FileList }
  | { type: 'local_file_removed' }
  | { type: 'file_removed' }
  | { type: 'file_data_url_calculated'; result: ReadResult }
  | {
      type: 'handle_upload_request_reply'
      payload: RemoteResult<InputFileUploadResult>
    }
  | { type: 'handle_upload_event'; event: FileUploadMsg }

export function makeFileInputProgram({
  actionKey,
  inputKey,
  initialValue,
  remote,
  typeInfo,
}: FileInputOptions): InputProgram<Msg, Model> {
  const isImageField = typeInfo.kind.allowedFileTypes.every((type) =>
    type.startsWith('image/')
  )
  const savedImageURL =
    isImageField && initialValue ? initialValue.url : undefined

  const initialUploadState: Model = {
    isRequestingUpload: false,
    requestError: undefined,
    fileList: undefined,

    isUploading: false,
    uploadError: undefined,
    uploadStats: undefined,
    uploadCancel: undefined,
    isUploaded: false,
    isImageField,
    typeInfo,

    editorState: undefined,
    isReading: false,
    readError: undefined,

    savedImageURL,
    localImageURL: undefined,
    persistedImageURL: savedImageURL,
    uploadRequestURL: undefined,
  }

  const init: Change<Msg, Model> = [initialUploadState]

  function update(msg: Msg, model: Model): Change<Msg, Model> {
    switch (msg.type) {
      case 'set_editor_state':
        return [{ ...model, editorState: msg.editorState }]
      case 'files_added': {
        const { fileList } = msg

        const newModel: Model = {
          ...model,
          isReading: true,
          fileList,
        }

        const file = fileList[0]
        const effect = file
          ? mapEffect(
              readLocalImageDataURL(file),
              (result) => ({ type: 'file_data_url_calculated', result } as Msg)
            )
          : undefined

        return [newModel, effect]
      }
      case 'local_file_removed': {
        return [
          {
            ...model,
            localImageURL: undefined,
            fileList: undefined,
          },
        ]
      }
      case 'file_removed': {
        return [
          {
            ...model,
            persistedImageURL: undefined,
            localImageURL: undefined,
            fileList: undefined,
          },
        ]
      }
      case 'file_data_url_calculated': {
        const { result } = msg
        const newModel = { ...model, isReading: false }

        switch (result.type) {
          case 'value':
            const localImageURL = result.value
            if (typeof localImageURL !== 'string') {
              throw new Error('expected a data URL string')
            }

            return [{ ...newModel, localImageURL }]
          case 'error':
            return [{ ...newModel, readError: result.error }]
          default:
            return absurd(result)
        }
      }
      case 'handle_upload_request_reply': {
        const { payload } = msg
        const newModel = { ...model, isRequestingUpload: false }
        const requestError = {
          message: 'Failed to upload file due to an unexpected error.',
        }

        switch (payload.type) {
          case 'reply': {
            const result = payload.reply
            switch (result.type) {
              case 'upload_request_url': {
                const uploadRequestURL = result.url
                if (!(model.fileList && model.fileList[0])) {
                  return [newModel]
                }

                const fileUploadSub = makeFileUploadSubscription(
                  uploadRequestURL,
                  model.fileList[0]
                )

                return [
                  {
                    ...newModel,
                    uploadRequestURL,
                    isUploading: true,
                    uploadCancel: fileUploadSub.cancel,
                  },
                  mapEffect(
                    fileUploadSub.effect,
                    (event) => ({ type: 'handle_upload_event', event } as Msg)
                  ),
                ]
              }
              case 'action_not_found':
              case 'input_not_found':
              case 'input_type_not_file':
              case 'file_upload_not_supported':
                return [{ ...newModel, requestError }]
              default:
                return absurd(result)
            }
          }
          case 'internal_error':
            return [
              {
                ...newModel,
                requestError,
              },
            ]
          case 'network_error':
            return [{ ...newModel, requestError: payload }]
          default:
            return absurd(payload)
        }
      }
      case 'handle_upload_event': {
        const { event } = msg
        const finishedModel: Model = {
          ...model,
          isUploading: false,
          uploadError: undefined,
          uploadCancel: undefined,
        }

        switch (event.type) {
          case 'upload_progress': {
            const { uploadedByteCount, totalByteCount } = event
            return [
              { ...model, uploadStats: { uploadedByteCount, totalByteCount } },
            ]
          }
          case 'upload_cancelled': {
            const uploadError = {
              message: 'Upload was cancelled. Please try again.',
            }
            return [{ ...finishedModel, uploadError }]
          }
          case 'upload_complete':
            return [model]
          case 'success':
            return [{ ...finishedModel, isUploaded: true }]
          case 'error':
            return [
              {
                ...finishedModel,
                uploadError: { message: 'Failed to upload file' },
              },
            ]
          default:
            return absurd(event)
        }
      }
    }
  }

  const performPreSubmit = (model: Model): Change<Msg, Model> | undefined => {
    if (!model.fileList) {
      return
    }

    if (model.isUploaded) {
      // No need to kick-off another upload
      return
    }

    const uploadRequest: Effect<Msg> = mapEffect(
      remote.loadEffect2<InputFileUploadRequest, InputFileUploadResult>({
        payload: {
          type: 'input_image_file_upload',
          inputKey,
          actionKey,
        },
      }),
      (payload) => ({ type: 'handle_upload_request_reply', payload } as Msg)
    )

    const effect = model.uploadCancel
      ? batchEffects([model.uploadCancel, uploadRequest])
      : uploadRequest

    return [{ ...model, isRequestingUpload: true }, effect]
  }

  const getPreSubmitStatus = (model: Model): PreSubmitStatus => {
    if (!model.fileList || model.isUploaded) {
      return { type: 'success' }
    }

    if (model.isRequestingUpload || model.isUploading) {
      return { type: 'loading' }
    }

    const error = model.requestError || model.uploadError
    if (error) {
      return { type: 'error', message: error.message }
    }

    throw new Error('unexpected_pre_submit_state')
  }

  const getSubmissionValue = (model: Model) => {
    if (model.uploadRequestURL && model.isUploaded) {
      return {
        type: 'upload_url',
        data: model.uploadRequestURL,
      }
    }

    if (model.persistedImageURL) {
      return {
        type: 'url',
        data: model.persistedImageURL,
      }
    }

    return
  }

  return {
    init,
    update,
    done,
    view,
    performPreSubmit,
    getPreSubmitStatus,
    getSubmissionValue,
  }
}

function done(model: Model) {
  if (model.uploadCancel) {
    model.uploadCancel(() => {})
  }
}

function view(model: Model, formView: FormView, dispatch: Dispatch<Msg>) {
  const { typeInfo } = model

  /*
  const { typeInfo, localImageURL, persistedImageURL } = model
  if (model.isImageField) {
    return (
      <ImageField
        {...{
          title: typeInfo.title,
          description: typeInfo.description,
          isRequired: !!typeInfo.required,
          isEnabled: !typeInfo.disabled && formView.isEnabled,
          acceptedFileTypes: typeInfo.kind.allowedFileTypes,

          localImageURL,
          persistedImageURL,

          fileList: model.fileList,
          onFileList(fileList) {
            dispatch({ type: 'files_added', fileList })
          },

          onLocalRemove() {
            dispatch({ type: 'local_file_removed' })
          },

          onRemove() {
            dispatch({ type: 'file_removed' })
          },

          editorState: model.editorState,
          onEditorStateChange(editorState) {
            dispatch({ type: 'set_editor_state', editorState })
          },
        }}
      />
    )
  }
  */

  return (
    <FileField
      {...{
        title: typeInfo.title,
        description: typeInfo.description,
        isRequired: !typeInfo.optional,
        isEnabled: !typeInfo.disabled && formView.isEnabled,
        acceptedFileTypes: typeInfo.kind.allowedFileTypes,
        value: model.fileList,
        onValue(fileList) {
          dispatch({ type: 'files_added', fileList })
        },
      }}
    />
  )
}
