import { makeActionProgram } from './action'
import { Card, CardHeader, CardSection } from '../../../views/card'
import { Banner } from '../../../views/banner'
import { RouterWithLink } from '../side'
import { Remote } from '../remote'
import { Effect, Program } from 'center/compiled/util/raj'
import { ReactNode } from 'react'
import { InputTypeInfo } from './input'
import { RenderedAction } from 'center/src/ui-bridge'

export type ActionWidget = RenderedAction

export type FormInput = InputTypeInfo & { key: string; adaptive: boolean }

type ActionWidgetOptions = {
  widget: ActionWidget
  widgetIndex: number
  remote: Remote
  router: RouterWithLink & { reload: () => Effect<never> }
}

export function makeProgram({
  widget: action,
  widgetIndex,
  remote,
  router,
}: ActionWidgetOptions): Program<unknown, unknown, ReactNode> {
  const { selectionError } = action
  if (selectionError) {
    return {
      init: [undefined],
      update: () => [undefined],
      view() {
        return (
          <Card>
            <CardHeader title={action.title} />
            <CardSection>
              <Banner
                {...{
                  color: 'red',
                  title: selectionError.message,
                }}
              />
            </CardSection>
          </Card>
        )
      },
    }
  }

  const autoFocusFirstInput = widgetIndex === 0
  return makeActionProgram({
    action,
    selection: [],
    autoFocusFirstInput,
    remote,
    router,
  })
}
