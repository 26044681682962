import { ReactNode } from 'react'
import { TextField } from '../../../../views/text-field'

type Model = {
  equalTo: string
  isEnabled: boolean
  autoFocus: boolean
}

type ViewOptions = {
  equalToLabel: string
}

type FlatFilter = {
  op: string
  value: string
}

export type FilterImpl<Model> = {
  init: (opts: {}) => Model
  extract: (model: Model) => FlatFilter[]
  restore: (model: Model, flatFilters: FlatFilter[]) => Model
  view: (
    model: Model,
    onValue: (newModel: Model) => void,
    viewOptions: ViewOptions
  ) => ReactNode
}

const init = (): Model => ({
  equalTo: '',
  isEnabled: false,
  autoFocus: false,
})

const extract = (model: Model) => {
  return model.equalTo ? [{ op: 'eq', value: model.equalTo }] : []
}

const restore = (model: Model, flatFilters: FlatFilter[]): Model => {
  let equalTo = ''
  flatFilters.forEach((filter) => {
    if (filter.op === 'eq') {
      equalTo = filter.value
    }
  })
  return { ...model, equalTo }
}

function view(
  model: Model,
  onValue: (newModel: Model) => void,
  viewOptions: ViewOptions
) {
  return (
    <TextField
      {...{
        title: viewOptions.equalToLabel,
        isEnabled: model.isEnabled,
        autoFocus: model.autoFocus,
        value: model.equalTo,
        onValue(equalTo) {
          onValue({
            ...model,
            equalTo,
          })
        },
      }}
    />
  )
}

export const program: FilterImpl<Model> = { init, view, extract, restore }
