import React, { ReactNode } from 'react'
import { batchEffects, mapEffect } from 'center/compiled/util/raj-compose'
import {
  CardHeader,
  CardSection,
  Card,
  Paragraph,
  List,
} from '../../../views/card'
import { Banner } from '../../../views/banner'
import { Form } from '../../../views/form'
import { Button, ButtonSet } from '../../../views/button'
import {
  InputProgram,
  InputTypeInfo,
  makeInputProgram,
  PreSubmitStatus,
} from './input'
import { ActionWidget } from './action-widget'
import { Change, Dispatch, Effect, Program } from 'center/compiled/util/raj'
import { Remote, RemoteResult } from '../remote'
import { absurd } from '../../../util/exhaustiveness'
import { RouterWithLinkAndReload } from '.'

import type {
  ActionPerformPayload,
  ActionPerformResult,
  ActionPerformSuccess,
  ActionRenderPayload,
  ActionSideEffect,
  OutputItem,
  RenderedAction,
  RenderedInput,
} from 'center/src/ui-bridge'

type ActionOptions = {
  action: ActionWidget
  selection: string[]
  autoFocusFirstInput: boolean
  dismissed?: () => Effect<never>
  completed?: (successMessage?: string) => Effect<never>
  remote: Remote
  router: RouterWithLinkAndReload
}

type FormInputModel = {
  formInputModelDict: Record<string, unknown>
  formInputProgramDict: Record<string, InputProgram<unknown, unknown>>
}

type Model = FormInputModel & {
  form: unknown
  formInputs: RenderedInput[]
  autoFocusFirstInput: boolean
  isRedrawing: boolean
  redrawError?: { message: string }
  isPreSubmitting: boolean
  isSubmitting: boolean
  submitError?: { message: string }
  submitSuccess?: { message: string }
  submitOutput?: {
    label: string
    items: OutputItem[]
    finishSideEffect: ActionSideEffect | undefined
  }
  isSuccessClearable: boolean
  isFinished: boolean
  isReloading: boolean
  reloadError?: { message: string }
}

type Msg =
  | { type: 'input_program_msg'; key: string; programMsg: unknown }
  | {
      type: 'handle_form_redraw'
      error: { message: string } | undefined
      data: ActionWidget
    }
  | { type: 'dismiss_form' }
  | { type: 'submit_form' }
  | {
      type: 'submitted_form'
      result: RemoteResult<ActionPerformResult>
    }
  | { type: 'dismiss_submit_error' }
  | { type: 'dismiss_submit_success' }
  | { type: 'complete_success' }
  | {
      type: 'reloaded_action'
      data: RemoteResult<RenderedAction>
    }

type ViewOptions = {
  titleText: string
  submitText: string
  canDismiss: boolean
}

export function makeActionProgram({
  action,
  selection,
  autoFocusFirstInput,
  dismissed,
  completed,
  remote,
  router,
}: ActionOptions): Program<Msg, Model, ReactNode> {
  const { title, submitText } = action

  const d = data({ dismissed, completed, remote, router })

  const { init, update, done } = logic(d, {
    action,
    selection,
    autoFocusFirstInput,
    remote,
  })

  return {
    init,
    update,
    done,
    view(model: Model, dispatch: Dispatch<Msg>) {
      return view(model, dispatch, {
        titleText: title,
        submitText: submitText,
        canDismiss: !!dismissed,
      })
    },
  }
}

/*
type ActionPerformSuccess = {
  type: 'success'
  message: string
}

type ActionPerformError = {
  type: 'error'
  message: string
}

type ActionPerformResult = ActionPerformSuccess | ActionPerformError
*/

type Deps = {
  dismissed?: () => Effect<never>
  completed?: (successMessage?: string) => Effect<never>
  reload: Effect<never>
  reboot: Effect<never>
  reloadAction(
    actionId: string,
    selection: unknown
  ): Effect<RemoteResult<RenderedAction>>
  redirectToUrl(url: string): Effect<never>
  redrawAction(
    actionId: string,
    selection: unknown,
    inputState: unknown
  ): Effect<unknown>
  submit(
    actionKey: string,
    selection: string[],
    inputState: Record<string, unknown>
  ): Effect<RemoteResult<ActionPerformResult>>
}

type DepOptions = {
  dismissed?: () => Effect<never>
  completed?: (successMessage?: string) => Effect<never>
  remote: Remote
  router: RouterWithLinkAndReload
}

const data = ({ completed, dismissed, remote, router }: DepOptions): Deps => ({
  completed,
  dismissed,
  reload:
    router.reload ||
    (() => {
      window.location.reload()
    }),
  reboot: () => {
    window.location.href = window.location.origin
  },
  reloadAction: (actionId, _selection) =>
    remote.loadEffect2<ActionRenderPayload, RenderedAction>({
      payload: {
        type: 'action_render',
        actionKey: actionId,
      },
    }),
  redirectToUrl:
    (url: string): Effect<never> =>
    () => {
      window.location.assign(url)
    },
  redrawAction: (actionId, selection, inputState) =>
    remote.loadEffect({
      id: actionId,
      action: 'renderWithInput',
      params: {
        selection,
        inputState,
      },
    }),
  submit: (actionKey, selection, inputState) =>
    remote.loadEffect2<ActionPerformPayload, ActionPerformResult>({
      payload: {
        type: 'action_perform',
        actionKey,
        selection,
        inputState,
      },
    }),
})

// const Msg = union([
//   'InputProgramMsg',
//   'HandleFormRedraw',
//   'DismissForm',
//   'SubmitForm',
//   'SubmittedForm',
//   'DismissSubmitError',
//   'DismissSubmitSuccess',
//   'CompleteSuccess',
//   'ReloadedAction',
// ])

// TODO: These aren't great defaults in a lot of cases
// so we should let the backend provide these in context.
export function getDefaultValueForKind(inputType: InputTypeInfo) {
  const { kind } = inputType

  switch (kind.type) {
    case 'checkbox':
      return false
    case 'date':
      return Date.now()
    case 'number':
      return 0
    case 'text':
    case 'password':
    case 'rich_text':
      return ''
    case 'enum':
    case 'select':
      return kind.values[0]!.key
    case 'file':
      return
    default:
      absurd(kind)
  }
}

// TODO: These aren't great defaults in a lot of cases
// so we should let the backend provide these in context.
function getDefaultValueForInput(inputType: RenderedInput) {
  const { kind } = inputType

  switch (kind.type) {
    case 'checkbox':
      return false
    case 'date':
      return Date.now()
    case 'integer':
      return 0
    case 'text':
    case 'password':
    case 'rich_text':
      return ''
    case 'enum':
      return kind.values[0]!.key
    case 'file':
      return
    default:
      absurd(kind)
  }
}

function buildInputPrograms(
  actionKey: string,
  inputs: RenderedInput[],
  formState: Record<string, unknown>,
  remote: Remote
): FormInputModel {
  const formInputProgramDict: FormInputModel['formInputProgramDict'] = {}
  const formInputModelDict: FormInputModel['formInputModelDict'] = {}

  inputs.forEach((input) => {
    const { key } = input
    const fieldInfo = formState[key]

    const fieldValue =
      fieldInfo === undefined ? getDefaultValueForInput(input) : fieldInfo

    const program = makeInputProgram({
      actionKey,
      typeInfo: input as any,
      fieldInfo: {
        value: fieldValue,
      },
      remote,
    })

    formInputProgramDict[key] = program
    formInputModelDict[key] = program.init[0]
  })

  return {
    formInputProgramDict,
    formInputModelDict,
  }
}

function closeInputPrograms(
  programs: Record<string, InputProgram<unknown, unknown>>,
  models: Record<string, unknown>
): Effect<never> {
  const closeEffects: Effect<never>[] = []
  for (const [key, model] of Object.entries(models)) {
    const program = programs[key]
    if (program) {
      const { done } = program
      const closeEffect = done ? () => done(model) : undefined
      if (closeEffect) {
        closeEffects.push(closeEffect)
      }
    }
  }

  return batchEffects(closeEffects)
}

function diff(
  previous: string[],
  next: string[]
): [string[], string[], string[]] {
  const added = []
  const shared = []
  const removed = []

  const seen: Record<string, -1 | 0 | 1> = {}
  for (const i of previous) {
    seen[i] = -1
  }

  for (const i of next) {
    const v = seen[i]
    if (v === undefined) {
      seen[i] = 1
    }

    if (v === -1) {
      seen[i] = 0
    }
  }

  for (const [key, value] of Object.entries(seen)) {
    switch (value) {
      case -1:
        removed.push(key)
        break
      case 0:
        shared.push(key)
        break
      case 1:
        added.push(key)
        break
      default:
        throw new Error(value)
    }
  }

  return [added, shared, removed]
}

type LogicOptions = {
  action: ActionWidget
  selection: string[]
  autoFocusFirstInput: boolean
  remote: Remote
}

function logic(
  data: Deps,
  { action, selection, autoFocusFirstInput, remote }: LogicOptions
) {
  const init: [Model] = [
    {
      form: action.inputState,
      formInputs: action.inputs,
      autoFocusFirstInput,
      isRedrawing: false,
      isPreSubmitting: false,
      isSubmitting: false,
      isSuccessClearable: false,
      isFinished: false,
      isReloading: false,

      ...buildInputPrograms(
        action.key,
        action.inputs,
        action.inputState,
        remote
      ),
    },
  ]

  function handleSubmitStatus(
    payload: ActionPerformSuccess,
    model: Model,
    isActive: boolean
  ): Change<Msg, Model> {
    const isFinished = !isActive
    const newModel = { ...model, isFinished }

    const { completed } = data
    const shouldExitAction = isFinished && completed
    if (shouldExitAction) {
      return [newModel, completed(payload.message)]
    }

    return [
      {
        ...newModel,
        submitSuccess: { message: payload.message },
      },
    ]
  }

  function handleSubmitSideEffect(
    model: Model,
    sideEffect: ActionSideEffect
  ): [Model, Effect<Msg> | undefined, boolean] {
    const { type } = sideEffect
    switch (type) {
      case 'reboot':
        return [model, data.reboot, false]
      case 'refresh':
        return [model, data.reload, false]
      case 'redirect': {
        const { url } = sideEffect
        return [model, data.redirectToUrl(url), false]
      }
      case 'reset_action':
        return [
          {
            ...model,
            isReloading: true,
            isSuccessClearable: true,
          },
          mapEffect(
            data.reloadAction(action.key, selection),
            (data) =>
              ({
                type: 'reloaded_action',
                data: data,
              } as Msg)
          ),
          true,
        ]
      case 'show_output': {
        const { label, items, finishSideEffect } = sideEffect
        return [
          { ...model, submitOutput: { label, items, finishSideEffect } },
          undefined,
          true,
        ]
      }
      default:
        absurd(type)
    }
  }

  function handleSubmitResult(
    payload: ActionPerformResult,
    model: Model
  ): Change<Msg, Model> {
    switch (payload.type) {
      case 'action_not_found': {
        return [
          {
            ...model,
            submitError: { message: 'Action not found (TODO localize)' },
          },
        ]
      }
      case 'errors': {
        const error = payload.errors[0]
        if (error && payload.errors.length === 1) {
          return [
            {
              ...model,
              submitError: { message: error.message },
            },
          ]
        }

        return [
          {
            ...model,
            submitError: {
              message: 'Multiple action errors (TODO fix + localize)',
            },
          },
        ]
      }
      case 'success': {
        const sideEffectData = payload.sideEffect
        const [sideModel, sideEffect, isActive] = sideEffectData
          ? handleSubmitSideEffect(model, sideEffectData)
          : [model, undefined, false]

        const [statusModel, statusEffect] = handleSubmitStatus(
          payload,
          sideModel,
          isActive
        )

        const effects: Effect<Msg>[] = []
        if (sideEffect) {
          effects.push(sideEffect)
        }

        if (statusEffect) {
          effects.push(statusEffect)
        }

        return [statusModel, batchEffects(effects)]
      }
      default:
        absurd(payload)
    }
  }

  function serializeForm(model: Model) {
    const submission: Record<string, unknown> = {}
    for (const [key, program] of Object.entries(model.formInputProgramDict)) {
      const inputModel = model.formInputModelDict[key]
      const inputValue = program.getSubmissionValue(inputModel)
      submission[key] = inputValue
    }
    return submission
  }

  function getPreSubmitStatus(model: Model): PreSubmitStatus {
    const statuses: PreSubmitStatus[] = []

    for (const { key } of model.formInputs) {
      const program = model.formInputProgramDict[key]
      if (!(program && program.getPreSubmitStatus)) {
        continue
      }

      const inputModel = model.formInputModelDict[key]
      const status = program.getPreSubmitStatus(inputModel)

      if (status.type === 'error') {
        return status
      }

      statuses.push(status)
    }

    for (const status of statuses) {
      if (status.type === 'loading') {
        return status
      }
    }

    return { type: 'success' }
  }

  const update = (msg: Msg, model: Model): Change<Msg, Model> => {
    switch (msg.type) {
      case 'input_program_msg': {
        const { key, programMsg } = msg
        const program = model.formInputProgramDict[key]
        if (!program) {
          // Assume msg is super late
          return [model]
        }

        const inputModel = model.formInputModelDict[key]
        const [newInputModel, effect] = program.update(programMsg, inputModel)
        const programEffect = effect
          ? mapEffect(
              effect,
              (programMsg) =>
                ({
                  type: 'input_program_msg',
                  key,
                  programMsg,
                } as Msg)
            )
          : undefined

        const newModel = {
          ...model,
          formInputModelDict: {
            ...model.formInputModelDict,
            [key]: newInputModel,
          },
        }

        if (newModel.isPreSubmitting) {
          const status = getPreSubmitStatus(newModel)
          switch (status.type) {
            case 'loading':
              break
            case 'success': {
              const submitEffect = mapEffect(
                data.submit(action.key, selection, serializeForm(newModel)),
                (data) =>
                  ({
                    type: 'submitted_form',
                    error: undefined,
                    result: data,
                  } as Msg)
              )

              return [
                { ...newModel, isPreSubmitting: false },
                batchEffects([programEffect, submitEffect]),
              ]
            }
            case 'error':
              // TODO: cancel other pre-submits
              return [
                {
                  ...newModel,
                  isPreSubmitting: false,
                  isSubmitting: false,
                  submitError: status,
                },
                programEffect,
              ]
            default:
              absurd(status)
          }
        }

        let shouldRedraw = false
        const input = model.formInputs.find((i) => i.key === key)
        const isAdaptiveInput = !!(input && input.adaptive)
        if (isAdaptiveInput) {
          const oldValue = program.getSubmissionValue(inputModel)
          const newValue = program.getSubmissionValue(newInputModel)
          if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            shouldRedraw = true
          }
        }

        if (!shouldRedraw) {
          return [newModel, programEffect]
        }

        const redrawModel: Model = {
          ...newModel,
          isRedrawing: true,
          redrawError: undefined,
        }
        const redrawEffect = mapEffect(
          data.redrawAction(action.key, selection, serializeForm(newModel)),
          (data) =>
            ({
              type: 'handle_form_redraw',
              error: undefined,
              data: data,
            } as Msg)
        )

        return [redrawModel, batchEffects([programEffect, redrawEffect])]
      }
      case 'handle_form_redraw': {
        const { error, data: action } = msg
        const newModel: Model = {
          ...model,
          isRedrawing: false,
          redrawError: undefined,
        }
        if (error) {
          return [{ ...newModel, redrawError: error }]
        }

        // NOTE: redraws diff on the layout and creates
        // and destroys input programs, but does not mutate
        // the values of any existing fields.
        // For example, if someone added an image in an
        // unrelated field we wouldn't want to blow that away.

        // const {formInputProgramDict, formInputModelDict} = buildInputPrograms(
        //   action.data.inputs,
        //   action.data.inputState,
        //   dataOptions
        // )

        // const formInputProgramDict = { ...model.formInputProgramDict }
        // const formInputModelDict = { ...model.formInputModelDict }
        // for (const [key, program] of Object.entries(newProgramming)) {
        //   if (!formInputProgramDict[key]) {
        //     formInputProgramDict[key] = program
        //     formInputModelDict[key] = program.init[0]
        //   }
        // }

        const oldInputKeys = model.formInputs.map((i) => i.key)
        const newInputKeys = action.inputs.map((i) => i.key)
        const [, , removed] = diff(oldInputKeys, newInputKeys)
        const removedModelDict: Record<string, unknown> = {}
        for (const key of Object.keys(model.formInputProgramDict)) {
          if (removed.includes(key)) {
            removedModelDict[key] = model.formInputModelDict[key]
          }
        }

        const closeEffect = closeInputPrograms(
          model.formInputProgramDict,
          removedModelDict
        )

        const closeModel: Model = {
          ...newModel,
          form: action.inputState,
          formInputs: action.inputs,
        }

        return [closeModel, closeEffect]
      }
      case 'dismiss_form': {
        const { dismissed } = data
        return [model, dismissed ? dismissed() : undefined]
      }
      case 'submit_form': {
        const newModel = {
          ...model,
          isSubmitting: true,
          submitSuccess: undefined,
        }
        const newInputModels: Record<string, unknown> = {}
        const preSubmitEffects = []

        let hasPreSubmitTasks = false
        for (const field of model.formInputs) {
          const { key } = field
          const program = model.formInputProgramDict[key]
          const inputModel = model.formInputModelDict[key]

          if (!(program && program.performPreSubmit)) {
            newInputModels[key] = inputModel
            continue
          }

          const update = program.performPreSubmit(inputModel)
          if (!update) {
            newInputModels[key] = inputModel
            continue
          }

          hasPreSubmitTasks = true

          const [newInputModel, effect] = update
          const programEffect = effect
            ? mapEffect(
                effect,
                (programMsg) =>
                  ({
                    type: 'input_program_msg',
                    key,
                    programMsg,
                  } as Msg)
              )
            : undefined

          newInputModels[key] = newInputModel
          if (programEffect) {
            preSubmitEffects.push(programEffect)
          }
        }

        if (hasPreSubmitTasks) {
          const preSubmitEffect = batchEffects(preSubmitEffects)
          return [
            {
              ...newModel,
              isPreSubmitting: true,
              formInputModelDict: newInputModels,
            },
            preSubmitEffect,
          ]
        }

        const submitEffect = mapEffect(
          data.submit(action.key, selection, serializeForm(model)),
          (data) =>
            ({
              type: 'submitted_form',
              error: undefined,
              result: data,
            } as Msg)
        )

        return [newModel, batchEffects([submitEffect])]
      }
      case 'submitted_form': {
        const { result } = msg
        const newModel = {
          ...model,
          isSubmitting: false,
          submitError: undefined,
          submitSuccess: undefined,
        }

        switch (result.type) {
          case 'internal_error': {
            return [
              {
                ...newModel,
                submitError: {
                  message: 'An unexpected error occurred.',
                },
              },
            ]
          }
          case 'network_error': {
            return [
              {
                ...newModel,
                submitError: { message: result.message },
              },
            ]
          }
          case 'reply': {
            return handleSubmitResult(result.reply, newModel)
          }
          default:
            return absurd(result)
        }
      }
      case 'dismiss_submit_error':
        return [{ ...model, submitError: undefined }]
      case 'dismiss_submit_success':
        return [{ ...model, submitSuccess: undefined }]
      case 'complete_success': {
        const { finishSideEffect } = model.submitOutput || {}
        const [newModel, sideEffect, isActive] = finishSideEffect
          ? handleSubmitSideEffect(model, finishSideEffect)
          : [model, undefined, false]

        const isFinished = !isActive
        const doneModel = { ...newModel, isFinished }
        const { completed } = data
        const shouldExitAction = isFinished && completed
        return [
          doneModel,
          shouldExitAction
            ? sideEffect
              ? batchEffects([completed(), sideEffect])
              : completed()
            : sideEffect,
        ]
      }
      case 'reloaded_action': {
        const newModel: Model = {
          ...model,
          isReloading: false,
          reloadError: undefined,
          submitOutput: undefined,
          submitSuccess: undefined,
        }

        switch (msg.data.type) {
          case 'internal_error': {
            return [
              {
                ...newModel,
                submitError: {
                  message: 'An unexpected error occurred.',
                },
              },
            ]
          }
          case 'network_error': {
            return [{ ...newModel, reloadError: { message: msg.data.message } }]
          }
          case 'reply': {
            const action = msg.data.reply
            const closeEffect = closeInputPrograms(
              model.formInputProgramDict,
              model.formInputModelDict
            )

            return [
              {
                ...newModel,
                form: action.inputState,
                formInputs: action.inputs,

                ...buildInputPrograms(
                  action.key,
                  action.inputs,
                  action.inputState,
                  remote
                ),
              },
              closeEffect,
            ]
          }
          default:
            return absurd(msg.data)
        }
      }
      default:
        return absurd(msg)
    }
  }

  function done(model: Model) {
    for (const { key } of model.formInputs) {
      const program = model.formInputProgramDict[key]
      const inputModel = model.formInputModelDict[key]

      if (program && program.done) {
        program.done(inputModel)
      }
    }
  }

  return { init, update, done }
}

function statusBannerView(model: Model, dispatch: Dispatch<Msg>) {
  if (model.submitSuccess) {
    return (
      <Banner
        {...{
          color: 'blue',
          title: model.submitSuccess.message,
          onDismiss: model.isSuccessClearable
            ? () => dispatch({ type: 'dismiss_submit_success' })
            : undefined,
        }}
      />
    )
  }

  if (model.submitError) {
    return (
      <Banner
        {...{
          color: 'red',
          title: model.submitError.message,
          onDismiss: () => dispatch({ type: 'dismiss_submit_error' }),
        }}
      />
    )
  }

  return null
}

function submitOutputView(submitOutput: NonNullable<Model['submitOutput']>) {
  const { label, items } = submitOutput

  return (
    <>
      <Paragraph>
        <b>{label}</b>
      </Paragraph>
      {items && items.length > 0 && (
        <List
          items={items.map((item) => ({
            key: item.key,
            // TODO: use value types here to make this more powerful
            value: (
              <Paragraph style={{ wordWrap: 'break-word' }}>
                {item.text}
              </Paragraph>
            ),
          }))}
        />
      )}
    </>
  )
}

function view(model: Model, dispatch: Dispatch<Msg>, viewOptions: ViewOptions) {
  const { titleText: labelText, submitText, canDismiss } = viewOptions
  const isEnabled = !(model.isSubmitting || model.isFinished)

  return (
    <Card noBorder={canDismiss}>
      <CardHeader title={labelText} />

      <Form onSubmit={() => dispatch({ type: 'submit_form' })}>
        <CardSection>
          {model.formInputs.length > 0 && (
            <>
              {!model.submitOutput && statusBannerView(model, dispatch)}

              {model.formInputs.map((input, index) => {
                const { key } = input
                const program = model.formInputProgramDict[key]
                const inputModel = model.formInputModelDict[key]

                if (!(program && inputModel !== undefined)) {
                  return null
                }

                return (
                  <React.Fragment key={key}>
                    {program.view(
                      inputModel,
                      {
                        isEnabled,
                        autoFocus: model.autoFocusFirstInput && index === 0,
                      },
                      (programMsg) => {
                        dispatch({
                          type: 'input_program_msg',
                          key,
                          programMsg,
                        })
                      }
                    )}
                  </React.Fragment>
                )
              })}
            </>
          )}

          {model.formInputs.length === 0 && statusBannerView(model, dispatch)}
          {model.submitOutput ? (
            <>
              {submitOutputView(model.submitOutput)}
              <ButtonSet>
                <Button
                  {...{
                    type: 'button',
                    title: 'Finish',
                    isEnabled: true,
                    onClick() {
                      dispatch({ type: 'complete_success' })
                    },
                  }}
                />
              </ButtonSet>
            </>
          ) : (
            <ButtonSet>
              <Button
                {...{
                  type: 'submit',
                  kind: canDismiss ? 'primary' : 'normal',
                  title: submitText,
                  isEnabled,
                }}
              />
              {canDismiss && (
                <Button
                  {...{
                    type: 'button',
                    title: 'Cancel',
                    isEnabled,
                    onClick() {
                      dispatch({ type: 'dismiss_form' })
                    },
                  }}
                />
              )}
            </ButtonSet>
          )}
        </CardSection>
      </Form>
    </Card>
  )
}
