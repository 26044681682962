import { PropsWithChildren, ReactNode } from 'react'
import { ClickGroup } from './click-group'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  cursor: default;

  &:hover {
    color: #fff;
  }
`

const Modal = styled.div`
  background-color: #fff;
  margin: 30px auto;
  max-width: 640px;
`

const ModalContent = styled.div`
  padding: 1px 0px;
`

type OverlayWashProps = {
  onDismiss: () => void

  // The caller needs to wrap their inner content such
  // that the <ClickGroup/> gets the fullest outside.
  wrapContent: (wrapper: (node: ReactNode) => ReactNode) => ReactNode
}

export function OverlayWash({ onDismiss, wrapContent }: OverlayWashProps) {
  return (
    <Container>
      <CloseButton
        {...{
          onClick: () => onDismiss(),
        }}
      >
        ✕
      </CloseButton>
      {wrapContent((children) => (
        <ClickGroup onOutsideClick={() => onDismiss()}>{children}</ClickGroup>
      ))}
    </Container>
  )
}

type ModalOverlayProps = PropsWithChildren<{
  onDismiss: () => void
}>

export function ModalOverlay({ onDismiss, children }: ModalOverlayProps) {
  return (
    <OverlayWash
      onDismiss={onDismiss}
      wrapContent={(contentWrapper) => (
        <Modal>{contentWrapper(<ModalContent>{children}</ModalContent>)}</Modal>
      )}
    />
  )
}
