import { createEmitter } from '../../util/emitter'
import {
  Subscription,
  withSubscriptions,
  mapSubscription,
} from '../../util/raj-subscription'
import styled, { keyframes } from 'styled-components'
import { absurd } from '../../util/exhaustiveness'
import { Effect } from 'center/compiled/util/raj'

type NewToast = { key: string; message: string }

type Toast = NewToast & { createdAt: number }

type Model = {
  toasts: Toast[]
}

type Msg =
  | { type: 'add_toast'; key: string; message: string }
  | { type: 'tick' }

export type ToastReporter = {
  showToast(toast: NewToast): Effect<never>
}

export type ToastEmitter = ToastReporter & {
  subscribe: () => Subscription<NewToast>
}

export function createToastEmitter(): ToastEmitter {
  const toastEmitter = createEmitter<NewToast>({
    shouldPrime: false,
  })

  return {
    subscribe: toastEmitter.subscribe,
    showToast(toast) {
      return () => toastEmitter.emit(toast)
    },
  }
}

type TimeEmitter = {
  subscribe: () => Subscription<number>
}

export function createTimeEmitter(interval: number): TimeEmitter {
  let intervalId: NodeJS.Timer | undefined

  const timeEmitter = createEmitter({
    shouldPrime: true,
    initialValue: Date.now(),
    onListeningChange(isListening) {
      if (isListening) {
        intervalId =
          intervalId ||
          setInterval(() => {
            timeEmitter.emit(Date.now())
          }, interval)
      } else {
        if (intervalId) {
          clearInterval(intervalId)
          intervalId = undefined
        }
      }
    },
  })

  return {
    subscribe: timeEmitter.subscribe,
  }
}

export function makeProgram(toastEmitter: ToastEmitter) {
  const timeEmitter = createTimeEmitter(1000)
  const init: [Model] = [
    {
      toasts: [],
    },
  ]

  const update = (msg: Msg, model: Model): [Model] => {
    switch (msg.type) {
      case 'add_toast':
        const { key, message } = msg
        return [
          {
            toasts: model.toasts.filter(isNotPruned).concat({
              key,
              message,
              createdAt: Date.now(),
            }),
          },
        ]
      case 'tick':
        return [{ ...model, toasts: model.toasts.filter(isNotPruned) }]
      default:
        absurd(msg)
    }
  }

  const subscriptions = (model: Model) => ({
    toasts: () =>
      mapSubscription(
        toastEmitter.subscribe(),
        ({ key, message }: NewToast) => ({
          type: 'add_toast',
          key,
          message,
        })
      ),
    time: model.toasts.length
      ? () => mapSubscription(timeEmitter.subscribe(), () => ({ type: 'tick' }))
      : undefined,
  })

  return withSubscriptions({ init, update, subscriptions, view })
}

const toastDuration = 5 * 1000
const pruneDuration = 2 * toastDuration

function isActive(toast: Toast) {
  return toast.createdAt + toastDuration > Date.now()
}

function isNotPruned(toast: Toast) {
  return toast.createdAt + pruneDuration > Date.now()
}

function newestFirst(a: Toast, b: Toast) {
  return a.createdAt - b.createdAt
}

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  left: 0px;
  right: 0px;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5) translate(0, 50%);
  }

  to {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
`

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`

const ToastList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`

const ToastItem = styled.li<{ isActive?: boolean }>`
  display: block;
  padding: 12px 16px;
  margin-top: 12px;
  border-radius: 2px;
  background-color: #345;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
  color: #eef4ff;
  text-shadow: 0 0 2px #123;

  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  animation: ${(props) => (props.isActive ? fadeIn : fadeOut)} 0.25s ease;
  transition: visibility 0.25s linear;
`

function view(model: Model) {
  const toasts = model.toasts.filter(isNotPruned).sort(newestFirst)

  if (!toasts.length) {
    return null
  }

  return (
    <ToastContainer>
      <ToastList>
        {toasts.map((toast) => (
          <ToastItem
            key={toast.key + toast.createdAt}
            isActive={isActive(toast)}
          >
            {toast.message}
          </ToastItem>
        ))}
      </ToastList>
    </ToastContainer>
  )
}
