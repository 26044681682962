import { ReactChild, ReactNode } from 'react'
import styled from 'styled-components'
import { Paragraph, Link, ExternalLink, Code } from '../../../views/card'
import { Badge } from '../../../views/badge'
import { Secret } from './secret'
import { Remote } from '../remote'
import { absurd } from '../../../util/exhaustiveness'
import { RenderedValue } from 'center/src/ui-bridge'
import type {
  FieldLink,
  FieldValue,
  TextFieldValue,
  IntegerFieldValue,
  DateFieldValue,
  EnumFieldValue,
  SecretFieldValue,
  BadgeColor,
} from 'center/src/ui/field'

const PreformattedText = styled.pre`
  white-space: pre-wrap;
`

const Time = styled.time`
  white-space: nowrap;
`

type LinkProps = {
  link: FieldLink
  makeLocalLink: (href: string) => string
  children: ReactChild
}

function Linkable({ link, makeLocalLink, children }: LinkProps) {
  switch (link.type) {
    case 'external':
      return <ExternalLink href={link.url}>{children}</ExternalLink>
    case 'local':
      return <Link href={makeLocalLink(link.url)}>{children}</Link>
    default:
      absurd(link)
  }
}

type ValueViewC = {
  value: FieldValue
  field: RenderedValue
  makeLocalLink: (href: string) => string
  remote: Remote
}

export function makeFieldView({
  value,
  field,
  makeLocalLink,
  remote,
}: ValueViewC): ReactNode {
  const { kind } = field

  switch (kind.type) {
    case 'text':
    case 'message': {
      const v = value as TextFieldValue
      // case 'reference':
      if (v.text == null) {
        return <Paragraph>{field.optionalText}</Paragraph>
      }

      if (kind.preformatted) {
        return (
          <Code>
            <PreformattedText>{v.text}</PreformattedText>
          </Code>
        )
      }

      if (v.link) {
        return (
          <Paragraph>
            <Linkable {...{ link: v.link, makeLocalLink }}>{v.text}</Linkable>
          </Paragraph>
        )
      }

      return <Paragraph>{v.text}</Paragraph>
    }
    case 'integer': {
      const v = value as IntegerFieldValue

      if (v.number == null) {
        return <Paragraph>{field.optional}</Paragraph>
      }

      if (v.link) {
        return (
          <Paragraph>
            <Linkable {...{ link: v.link, makeLocalLink }}>{v.number}</Linkable>
          </Paragraph>
        )
      }

      return <Paragraph>{v.number}</Paragraph>
    }
    case 'date': {
      const v = value as DateFieldValue

      return (
        <Time title={v.completeDate} dateTime={v.rawDate}>
          {v.link ? (
            <Linkable {...{ link: v.link, makeLocalLink }}>
              {v.relativeDate}
            </Linkable>
          ) : (
            v.relativeDate
          )}
        </Time>
      )
    }
    /*
    case 'image': {
      if (!value.result) {
        return <Paragraph>{value.optional}</Paragraph>
      }

      const { result } = value
      switch (result.type) {
        case 'error': {
          return <Paragraph>{result.message}</Paragraph>
        }
        case 'image': {
          const { url, altText, width, height, details } = result
          return <ImageDisplay {...{ url, altText, width, height, details }} />
        }
        default:
          return absurd(result)
      }
    }
    */
    case 'enum': {
      const v = value as EnumFieldValue
      const missingFieldEnumText = 'Unknown'
      const missingFieldEnumColor: BadgeColor = 'gray'

      switch (kind.style) {
        case 'badge': {
          const match = kind.values.find((ev) => ev.value === v.value)
          const option = match || {
            title: missingFieldEnumText,
            color: missingFieldEnumColor,
          }
          const { title, color } = option

          return <Badge label={title} color={color} />
        }
        case 'plain': {
          const match = kind.values.find((ev) => ev.value === v.value)
          return <Paragraph>{match?.title || missingFieldEnumText}</Paragraph>
        }
        default:
          return absurd(kind)
      }
    }
    case 'secret': {
      const v = value as SecretFieldValue
      const { title, description } = field
      const { isMultipleLines } = kind

      return (
        <Secret
          {...{
            value: v.secret,
            remote,
            viewOptions: {
              title: title,
              description,
              isMultipleLines,
            },
          }}
        />
      )
    }
    default:
      absurd(kind)
  }
}
