import { useCallback } from 'react'
import { LabeledFieldProps, SandwichLabeledField } from './field'
import styled from 'styled-components'

const BasicInput = styled.input``

type BaseFileInputComponent = typeof BasicInput

type FileFieldProps = {
  isEnabled: boolean
  isRequired: boolean
  acceptedFileTypes: string[]
  value: FileList | undefined
  onValue: (fileList: FileList) => void
}

export function BoundFileInput(
  props: FileFieldProps & { BaseComponent: BaseFileInputComponent }
) {
  const {
    BaseComponent,
    isRequired,
    isEnabled,
    acceptedFileTypes,
    value,
    onValue,
  } = props

  const fileRef = useCallback(
    (node: HTMLInputElement) => {
      // NOTE: In order to have the required validation work,
      // <input> must be set and it only can set a list, not
      // a single file, so we have to pass around file lists.
      if (node) {
        if (value === undefined) {
          // `input.files` is readonly so to clear the input
          // we clear `input.value` instead
          // TODO: Does empty string work?
          node.value = null as any
        } else {
          node.files = value
        }
      }
    },
    [value]
  )

  return (
    <BaseComponent
      {...{
        ref: fileRef,
        type: 'file',
        accept: acceptedFileTypes.join(','),
        required: isRequired,
        disabled: !isEnabled,
        onChange(event: any) {
          const files = event.target.files
          if (files.length > 0) {
            onValue(files)
          }
        },
      }}
    />
  )
}

export function FileField(props: FileFieldProps & LabeledFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...fileFieldProps } =
    props
  return (
    <SandwichLabeledField
      {...{
        title,
        description,
        isEnabled: props.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <BoundFileInput {...{ ...fileFieldProps, BaseComponent: BasicInput }} />
    </SandwichLabeledField>
  )
}
