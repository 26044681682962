import styled, { css } from 'styled-components'
import { Icon } from './icon'
import { Paragraph } from './card'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

export const Container = styled.div``

export const Head = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
`

export const Side = styled.div`
  position: fixed;
  top: 80px;
  left: 0px;
  bottom: 0px;
`

export const Body = styled.div``

export const Sidebar = styled.nav``

const SidebarGroupInner = styled.div`
  margin: 0px 0px 15px 0px;
`

const SidebarGroupLabel = styled.b`
  font-size: 0.8em;
  font-weight: normal;
  color: #333;
  padding: 6px 15px;
`

type SidebarGroupProps = {
  label: string
  children: JSX.Element
}

export function SidebarGroup({ label, children }: SidebarGroupProps) {
  return (
    <SidebarGroupInner>
      {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
      {children}
    </SidebarGroupInner>
  )
}

const SidebarListInner = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SidebarSlot = styled.li``

const SidebarItem = styled.span<{ isActive?: boolean }>`
  display: block;
  text-decoration: none;
  color: #333;
  padding: 6px 18px 6px 15px;
  margin: 0px;
  font-size: 1em;

  &:hover {
    color: #000;
    text-decoration: underline;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: #000;
      font-weight: bold;
      text-decoration: none;
    `}
`

const SidebarLink = SidebarItem.withComponent('a')

type SidebarListProps = {
  items: {
    key: string
    href?: string
    children: JSX.Element
  }[]
}

export const SidebarList = ({ items }: SidebarListProps) => (
  <SidebarListInner>
    {items.map(({ key, ...item }) => (
      <SidebarSlot key={key}>
        {item.href ? <SidebarLink {...item} /> : <SidebarItem {...item} />}
      </SidebarSlot>
    ))}
  </SidebarListInner>
)

const MainSide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: none;
  display: flex;
`

const MainSideSide = styled.div`
  display: flex;
  align-self: stretch;
  width: 240px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  box-shadow: 1px 0 2px rgb(0 0 0 / 5%);
  overflow-y: auto;
  flex-direction: column;
`

const MainSideMain = styled.div`
  flex: 1;
  padding: 15px;
  overflow: scroll;
`

const MainContainer = styled.div`
  max-width: 960px;
`

type MainSideLayoutProps = {
  side: ReactNode
  main: ReactNode
}

export const MainSideLayout = ({ side, main }: MainSideLayoutProps) => (
  <MainSide>
    <MainSideSide>{side}</MainSideSide>
    <MainSideMain>
      <MainContainer>{main}</MainContainer>
    </MainSideMain>
  </MainSide>
)

export const CenterLayout = styled.div`
  max-width: 640px;
  margin: 10vh auto 20px;
`

const MenuContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
`
const MenuTitle = styled(Paragraph)`
  font-weight: bold;
`
const MenuDescription = styled(Paragraph)`
  font-size: 0.9em;
`
const MenuHandle = styled.div`
  padding: 0px 15px;
`

const MenuAction = styled.a`
  padding: 10px;
  margin: 0px 10px 0px 0px;
  border-radius: 50%;
  border: 1px solid #eee;
  color: #aaa;
  font-size: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  justify-content: center;
  width: 38px;

  &:hover {
    border: 1px solid #ccc;
    border-top: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #000;
  }
`

export type ActionLink = {
  icon: IconName
  link: string
}

export type MenuProps = {
  title: string
  description: string
  actionLink?: ActionLink
}

export const Menu = ({
  title,
  description,
  actionLink: flipAction,
}: MenuProps) => {
  return (
    <MenuContainer>
      <MenuHandle>
        <MenuTitle>{title}</MenuTitle>
        <MenuDescription>{description}</MenuDescription>
      </MenuHandle>
      {flipAction && (
        <MenuAction href={flipAction.link}>
          <Icon name={flipAction.icon} />
        </MenuAction>
      )}
    </MenuContainer>
  )
}
