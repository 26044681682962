import { Paragraph, CardSection } from './card'
import styled, { css } from 'styled-components'
import { ReactNode } from 'react'
import { absurd } from '../util/exhaustiveness'

const TableInner = styled.table`
  width: 100%;
  border-spacing: 0px;
`

const TableHeadRow = styled.tr`
  background-color: #f9fbfb;
  box-shadow: inset 0 -1px 0px #f7f7f7;
`

const TableBodyRow = styled.tr`
  box-shadow: inset 0 -1px 0px #f7f7f7;

  &:last-child {
    box-shadow: none;
  }
`

function getSizeWidth(props: CellProps): string | undefined {
  const { size } = props
  switch (size) {
    case 'auto':
      return
    case 'maximized':
      return '100%'
    case 'minimized':
      return '1%'
    default:
      absurd(size)
  }
}

type Size = 'auto' | 'maximized' | 'minimized'
type Alignment = 'left' | 'right'

type CellProps = {
  alignment: Alignment
  size: Size
}

const TableCell = styled.td<CellProps>`
  ${(props) =>
    props.alignment === 'right' &&
    css`
      text-align: right;
    `}
  ${(props) => {
    const width = getSizeWidth(props)
    return (
      width &&
      css`
        width: ${width};
      `
    )
  }}

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
`

const TableHeadCell = styled(TableCell)`
  color: #222;
  font-weight: normal;
  font-size: 0.9em;
  padding: 10px 10px;
  text-transform: uppercase;
`

const TableBodyCell = styled(TableCell)`
  padding: 8px 10px;
`

export type TableColumn<Item> = {
  key: string
  title: ReactNode
  alignment: Alignment
  size: Size
  value: (item: Item) => ReactNode
}

type TableProps<Item> = {
  items: Item[]
  itemKey: (item: Item) => string
  columns: TableColumn<Item>[]
  emptyText: string
  hasHeader: boolean
}

export function Table<Item>({
  items,
  itemKey,
  columns,
  emptyText,
  hasHeader,
}: TableProps<Item>) {
  const hasItems = items.length > 0
  return (
    <>
      <TableInner>
        {hasHeader && (
          <thead>
            <TableHeadRow>
              {columns.map(({ key, title, alignment, size }) => (
                <TableHeadCell key={key} {...{ alignment, size }}>
                  {title}
                </TableHeadCell>
              ))}
            </TableHeadRow>
          </thead>
        )}
        {hasItems && (
          <tbody>
            {items.map((item) => (
              <TableBodyRow key={itemKey(item)}>
                {columns.map(({ key, value, alignment, size }) => (
                  <TableBodyCell key={key} {...{ alignment, size }}>
                    {value(item)}
                  </TableBodyCell>
                ))}
              </TableBodyRow>
            ))}
          </tbody>
        )}
      </TableInner>
      {!hasItems && (
        <CardSection>
          <Paragraph centered>{emptyText}</Paragraph>
        </CardSection>
      )}
    </>
  )
}
