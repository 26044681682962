import styled, { keyframes } from 'styled-components'
import { CardSection, Heading, Paragraph } from './card'
import { Icon } from './icon'
import { Pad } from './spacing'

const Group = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`

const Dot = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ccc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`

const firstDot = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const lastDot = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const middleDot = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`

const FirstDot = styled(Dot)`
  left: 8px;
  animation: ${firstDot} 0.6s infinite;
`

const LastDot = styled(Dot)`
  left: 56px;
  animation: ${lastDot} 0.6s infinite;
`

const LeftDot = styled(Dot)`
  left: 8px;
  animation: ${middleDot} 0.6s infinite;
`

const RightDot = styled(Dot)`
  left: 32px;
  animation: ${middleDot} 0.6s infinite;
`

const LoadingSpinner = () => {
  return (
    <Group>
      <FirstDot />
      <LeftDot />
      <RightDot />
      <LastDot />
    </Group>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const DelayedFadeIn = styled.div`
  opacity: 0;
  animation: ${fadeIn} linear 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`

export const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const LoadingIndicator = ({ message }: { message?: string }) => {
  return (
    <CardSection>
      <Centered>
        <DelayedFadeIn>
          <LoadingSpinner />
          <Paragraph>{message}</Paragraph>
        </DelayedFadeIn>
      </Centered>
    </CardSection>
  )
}

const Centered = styled.div`
  text-align: center;
`

export const ErrorIndicator = ({
  failure,
  message,
}: {
  failure: string
  message: string
}) => {
  return (
    <CardSection>
      <Centered>
        <Icon
          {...{
            name: 'exclamation-triangle',
            color: 'light',
            size: 'large',
          }}
        />
        <Pad>
          <Heading>{failure}</Heading>
          <Paragraph>{message}</Paragraph>
        </Pad>
      </Centered>
    </CardSection>
  )
}
