import { mapEffect } from 'center/compiled/util/raj-compose'
import { Icon } from '../../views/icon'
import { Button } from '../../views/button'

import styled from 'styled-components'
import { Pad } from '../../views/spacing'
import { Card, CardSection, Heading, Paragraph } from '../../views/card'
import { DelayedFadeIn } from '../../views/indicator'
import { ReactNode } from 'react'
import { EffectHttpClient } from '.'
import { Change, Dispatch, Effect, Program } from 'center/compiled/util/raj'
import { absurd } from '../../util/exhaustiveness'
import { FlowData } from './flow'

type Msg =
  | {
      type: 'set_authentication'
      data:
        | { type: 'ok'; flowData: FlowData }
        | { type: 'error'; message: string }
    }
  | { type: 'retry_authentication' }

type Model = {
  isAuthenticated: boolean
  isAuthenticating: boolean
  authenticationError?: { message: string }
}

export function makeProgram(
  effectHTTPClient: EffectHttpClient,
  fireCompletion: (data: FlowData) => void
): Program<Msg, Model, ReactNode> {
  const checkAuthentication = (): Effect<Msg> =>
    mapEffect(effectHTTPClient.post('/account/flow'), (data): Msg => {
      switch (data.type) {
        case 'value': {
          return {
            type: 'set_authentication',
            data: {
              type: 'ok',
              flowData: data.value as FlowData,
            },
          }
        }
        case 'error': {
          return {
            type: 'set_authentication',
            data: {
              type: 'error',
              message: (data.error as any).message,
            },
          }
        }
        default:
          absurd(data)
      }
    })

  const init = [
    {
      isAuthenticated: false,
      isAuthenticating: true,
    },
    checkAuthentication(),
  ] as [Model, Effect<Msg>]

  function update(msg: Msg, model: Model): Change<Msg, Model> {
    switch (msg.type) {
      case 'set_authentication': {
        const newModel = {
          ...model,
          isAuthenticating: false,
          authenticationError: undefined,
        }

        switch (msg.data.type) {
          case 'ok': {
            const flowData = msg.data.flowData
            return [
              { ...newModel, isAuthenticated: !flowData.path },
              () => fireCompletion(flowData),
            ]
          }
          case 'error':
            return [
              {
                ...newModel,
                authenticationError: { message: msg.data.message },
              },
            ]
          default:
            return absurd(msg.data)
        }
      }
      case 'retry_authentication':
        return [{ ...model, isAuthenticating: true }, checkAuthentication()]
      default:
        absurd(msg)
    }
  }

  return { init, update, view }
}

const AuthFrame = styled.div`
  padding: 30px 20px;
`

const AuthForm = styled.div`
  max-width: 420px;
  margin: 0px auto;
`

const AlertDialog = styled.div`
  text-align: center;

  span.fa {
    font-size: 4em;
    color: #555;
    margin: 15px 0px;
  }
`

const Alert = ({ children }: { children: ReactNode }) => (
  <AuthFrame>
    <AuthForm>
      <Card>
        <CardSection>
          <AlertDialog>{children}</AlertDialog>
        </CardSection>
      </Card>
    </AuthForm>
  </AuthFrame>
)

function view(model: Model, dispatch: Dispatch<Msg>) {
  if (model.isAuthenticating) {
    return (
      <DelayedFadeIn>
        <Alert>
          <Icon name="shield" />
          <Heading>Welcome</Heading>
          <Paragraph>Authenticating...</Paragraph>
        </Alert>
      </DelayedFadeIn>
    )
  }

  if (model.authenticationError) {
    return (
      <Alert>
        <Pad>
          <Icon name="exclamation-triangle" />
          <Heading>Failed to load authentication</Heading>
          <Paragraph>{model.authenticationError.message}</Paragraph>
        </Pad>
        <Button
          {...{
            title: 'Retry authentication',
            isEnabled: true,
            onClick() {
              dispatch({ type: 'retry_authentication' })
            },
          }}
        />
      </Alert>
    )
  }

  if (!model.isAuthenticated) {
    return (
      <Alert>
        <Icon name="check-circle" />
        <Heading>Please authenticate</Heading>
        <Paragraph>Could not auto-authenticate</Paragraph>
      </Alert>
    )
  }

  return (
    <Alert>
      <Icon name="check-circle" />
      <Heading>Welcome</Heading>
      <Paragraph>You are authenticated</Paragraph>
    </Alert>
  )
}
