type Request = {
  method: 'POST'
  url: string
  body: unknown
  headers: Record<string, string>
}

async function makeHTTPRequest({
  method,
  url,
  body,
  headers,
}: Request): Promise<unknown> {
  const res = await window.fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
  })
  return await res.json()
}

export type HttpClient = {
  post(path: string, body: unknown): Promise<unknown>
}

export function makeHTTPClient(
  baseURL: string,
  headers?: Record<string, string>
): HttpClient {
  return {
    post(path, body) {
      return makeHTTPRequest({
        method: 'POST',
        url: `${baseURL}${path}`,
        body,
        headers: headers || {},
      })
    },
  }
}
