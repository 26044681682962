import { program as dateProgram } from './date'
// import { program as enumProgram } from './enum'
import { program as numberProgram } from './number'
// import { program as referenceProgram } from './reference'
import { program as textProgram } from './text'
import { CardSection, CardHeader, Card } from '../../../../views/card'
import { Form } from '../../../../views/form'
import { Button, ButtonSection } from '../../../../views/button'
import { Icon } from '../../../../views/icon'
import { mapEffect } from 'center/compiled/util/raj-compose'

const valueTypeProgram = {
  // enum: enumProgram,
  date: dateProgram,
  number: numberProgram,
  // reference: referenceProgram,
  text: textProgram,

  // TODO: build this out more
}

function getFilterInitialState(info, flatFilters, loadEffect) {
  const { id, key, label, valueType, valueInfo } = info

  const program = valueTypeProgram[valueType]
  if (!program) {
    throw new Error(`Filter program not found for valueType "${valueType}"`)
  }

  let model = {
    ...program.init(valueInfo, { id, dataOptions: { loadEffect } }),
  }
  if (flatFilters) {
    model = program.restore(model, flatFilters)
  }

  return { key, label, valueType, valueInfo, model }
}

export function createFilterModel(values, routeOptions, loadEffect) {
  const filterableValues = values.filter((value) => value.filterable)

  const flatFilterDict = {}
  for (const [param, value] of Object.entries(routeOptions)) {
    const [prefix, key, op] = param.split('.')
    if (prefix === 'filter' && key && op) {
      const flatFilters = flatFilterDict[key] || []
      flatFilterDict[key] = flatFilters.concat({ op, value })
    }
  }

  return {
    filters: filterableValues.map((v) =>
      getFilterInitialState(v, flatFilterDict[v.key], loadEffect)
    ),
  }
}

export function clearFilter(model, filterKey) {
  return {
    ...model,
    filters: model.filters.map((filter) =>
      filter.key === filterKey ? getFilterInitialState(filter) : filter
    ),
  }
}

export function hasFiltersSet(model, filterKey) {
  return model.filters.some((filter) => {
    if (filterKey && filter.key !== filterKey) {
      return false
    }

    const program = valueTypeProgram[filter.valueType]
    const filters = program.extract(filter.model)
    return filters.length > 0
  })
}

export function extractFilters(model) {
  const filterParams = {}
  for (const filter of model.filters) {
    const program = valueTypeProgram[filter.valueType]
    const filters = program.extract(filter.model)

    filters.forEach(({ op, value }) => {
      filterParams[`filter.${filter.key}.${op}`] = value
    })
  }
  return filterParams
}

function updateInnerModel(value, filterKey, updateFn) {
  return {
    ...value,
    filters: value.filters.map((filter) => {
      return filter.key === filterKey
        ? { ...filter, model: updateFn(filter.model) }
        : filter
    }),
  }
}

function FilterSection({
  filterKey,
  viewOptions,
  value,
  onValue,
  isEnabled,
  autoFocus,
}) {
  const filter = value.filters.find((f) => f.key === filterKey)
  const program = valueTypeProgram[filter.valueType]

  return program.view(
    { ...filter.model, isEnabled, autoFocus },
    (newModel, effectHandler) => {
      onValue({
        model: updateInnerModel(value, filterKey, () => newModel),
        effectHandler: effectHandler
          ? {
              effect: mapEffect(effectHandler.effect, (result) => ({
                filterKey,
                result,
              })),
              handle(result, laterValue) {
                return updateInnerModel(
                  laterValue,
                  result.filterKey,
                  (laterModel) =>
                    effectHandler.update(result.result, laterModel)
                )
              },
            }
          : null,
      })
    },
    viewOptions
  )
}

function FormWrapper({ viewOptions, onClear, onCancel, onSubmit, content }) {
  const isEnabled = true

  return (
    <Form onSubmit={() => onSubmit()}>
      {content}
      <ButtonSection
        alternativeButtons={
          <Button
            {...{
              type: 'button',
              title: viewOptions.clearButton,
              isEnabled,
              onClick: () => onClear(),
            }}
          />
        }
      >
        <Button
          {...{
            type: 'submit',
            kind: 'primary',
            title: viewOptions.submitButton,
            isEnabled,
            onClick: () => onSubmit(),
          }}
        />
        {onCancel && (
          <Button
            {...{
              type: 'button',
              title: viewOptions.cancelButton,
              isEnabled,
              // TODO: cancel sucks because we
              // if we hit cancel we don't want to preserve the
              // existing state, we want to use an editing buffer
              onClick: () => onCancel(),
            }}
          />
        )}
      </ButtonSection>
    </Form>
  )
}

const BreadCrumb = () => {
  return (
    <span
      style={{
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: '0.5em',
      }}
    >
      <Icon name="chevron-right" />
    </span>
  )
}

export function QuickFilter({
  filterKey,
  viewOptions,
  value,
  onValue,
  isEnabled,

  onClear,
  onCancel,
  onSubmit,
}) {
  const filter = value.filters.find((f) => f.key === filterKey)

  return (
    <Card>
      <CardHeader
        title={
          <>
            {viewOptions.fullFilter}
            <BreadCrumb />
            {filter.label}
          </>
        }
      />
      <FormWrapper
        {...{
          viewOptions,
          onClear,
          onCancel,
          onSubmit,
          content: (
            <CardSection>
              <FilterSection
                {...{
                  value,
                  onValue,
                  isEnabled,
                  autoFocus: true,
                  filterKey,
                  viewOptions,
                }}
              />
            </CardSection>
          ),
        }}
      />
    </Card>
  )
}

export function FullFilter({
  value,
  onValue,
  onClear,
  onCancel,
  onSubmit,
  isEnabled,
  viewOptions,
}) {
  return (
    <Card>
      <CardHeader title={viewOptions.fullFilter} />
      <FormWrapper
        {...{
          viewOptions,
          onClear,
          onCancel,
          onSubmit,
          content: value.filters.map(({ key: filterKey, label }, index) => (
            <CardSection key={filterKey}>
              <b>{label}</b>

              <FilterSection
                {...{
                  key: filterKey,
                  value,
                  onValue,
                  isEnabled,
                  autoFocus: index === 0,
                  filterKey,
                  viewOptions,
                }}
              />
            </CardSection>
          )),
        }}
      />
    </Card>
  )
}
