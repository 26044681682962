import styled, { css } from 'styled-components'
import { absurd } from '../util/exhaustiveness'
import type { BadgeColor } from 'center/src/ui/field'
export type { BadgeColor } from 'center/src/ui/field'

type BadgeStyle = {
  textColor: string
  backgroundColor: string
}

function getBadgeColorStyle(color: BadgeColor): BadgeStyle {
  switch (color) {
    case 'blue':
      return { textColor: '#0b1a53', backgroundColor: '#c5dfff' }
    case 'green':
      return { textColor: '#094e12', backgroundColor: '#bbf5c4' }
    case 'red':
      return { textColor: '#5b0505', backgroundColor: '#f9bfbf' }
    case 'yellow':
      return { textColor: '#222', backgroundColor: '#f8f8f8' }
    case 'gray':
      return { textColor: '#1f262b', backgroundColor: '#d9e2e7' }
    default:
      absurd(color)
  }
}

const BadgeInner = styled.span<{ color: BadgeColor }>`
  display: inline-block;
  vertical-align: text-bottom;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 100px;

  ${(props) => {
    const { textColor, backgroundColor } = getBadgeColorStyle(props.color)
    return css`
      color: ${textColor};
      background-color: ${backgroundColor};
    `
  }}
`

type BadgeProps = {
  label: string
  color: BadgeColor
}

export function Badge({ label, color }: BadgeProps) {
  return <BadgeInner color={color}>{label}</BadgeInner>
}
