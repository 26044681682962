import { Fragment, ReactNode } from 'react'
import { makeRoutedProgram } from '../../util/raj-spa'
import { Program } from 'center/compiled/util/raj'
import { batchPrograms } from 'center/compiled/util/raj-compose'
import { createEmitter } from '../../util/emitter'
import {
  makeProgram as makeToastProgram,
  createToastEmitter,
  ToastEmitter,
  ToastReporter,
} from './toast'
import { LoadingIndicator, Cover } from '../../views/indicator'
import { Remote } from './remote'
import { absurd } from '../../util/exhaustiveness'
import { DocumentTitle } from '../../views/document-title'
import { Card, CardSection, Heading } from '../../views/card'
import { makeWidgetProgram, RouterWithLinkAndReload } from './widgets'
import { RouteEmitter } from './route'
import { ControlledRouterWithLink } from './side'
import {
  RenderedRoute,
  URLPathRenderPayload,
  URLPathRenderResult,
} from 'center/src/ui-bridge'
import { viewOnlyProgram } from './view-only-program'

export { makeRemote } from './remote'
export { makeSideProgram } from './side'

const initialProgram = viewOnlyProgram(() => (
  <Cover>
    <LoadingIndicator />
  </Cover>
))

export function makeFrontProgram(
  remote: Remote,
  parentRouter: ControlledRouterWithLink
): Program<unknown, unknown, ReactNode> {
  const toastEmitter = createToastEmitter()

  return batchPrograms<ReactNode>(
    [
      makeAppProgram(remote, parentRouter, toastEmitter),
      makeToastProgram(toastEmitter),
    ],
    ([app, toasts]) => {
      return (
        <>
          {app!()}
          {toasts!()}
        </>
      )
    }
  )
}

type RouteUrl = {
  route: string
  routeOptions: Record<string, string>
}

function parseRouteURL(routeURL: string): RouteUrl {
  const [path, queryString] = routeURL.split('?')
  const queryParams = new window.URLSearchParams(queryString)
  const routeOptions: Record<string, string> = {}
  for (const [key, value] of Array.from(queryParams.entries())) {
    routeOptions[key] = value
  }
  return { route: path || '', routeOptions }
}

function buildRouteURL(
  route: string,
  routeOptions: Record<string, string>
): string {
  const [basePath = ''] = route.split('?')
  const queryParams = new window.URLSearchParams()
  for (const [key, value] of Object.entries(routeOptions)) {
    if (value) {
      queryParams.set(key, value)
    }
  }

  const queryString = queryParams.toString()
  return queryString ? `${basePath}?${queryString}` : basePath
}

function makeAppProgram(
  remote: Remote,
  parentRouter: ControlledRouterWithLink,
  toastEmitter: ToastEmitter
) {
  return makeRoutedProgram({
    router: parentRouter,
    initialProgram,
    // SHIT(2020-07-19): using `route` as the variable name conflicts
    // with the transpilation of the async `getRouteProgram` below
    getRouteProgram(routePath, { keyed }) {
      return keyed(routePath, (router) => {
        const reloadEmitter = createEmitter<void>({
          shouldPrime: true,
          initialValue: undefined,
        })

        return makeRoutedProgram({
          router: reloadEmitter,
          initialProgram,
          getErrorProgram(error) {
            console.error('getErrorProgram', error)
            debugger
            return viewOnlyProgram(() => <p>An unexpected error occurred.</p>)
          },
          async getRouteProgram() {
            const result = await remote.loadAsync2<
              URLPathRenderPayload,
              URLPathRenderResult
            >({
              payload: {
                type: 'url_path_render',
                urlPath: routePath,
              },
            })

            switch (result.type) {
              case 'url_path_malformed':
              case 'route_not_found':
                return makeRouteErrorProgram({
                  error: new Error('Page not found'),
                })
              case 'route':
                break
              case 'error':
                return makeRouteErrorProgram({
                  error: new Error('An unexpected error occurred.'),
                })
              default:
                return absurd(result)
            }

            return makeRouteProgram({
              route: result,
              routeOptions: parseRouteURL(routePath).routeOptions,
              router: {
                ...parentRouter,
                ...router,
                link(val: string | RouteUrl) {
                  let nextURL: string
                  if (typeof val === 'string') {
                    nextURL = val
                  } else {
                    const { route, routeOptions } = val
                    nextURL = buildRouteURL(route, routeOptions)
                  }

                  return parentRouter.link(nextURL)
                },
                reload: () => () => {
                  reloadEmitter.emit()
                },
              },
              routeEmitter: {
                emit(route) {
                  const nextURL = buildRouteURL(route.route, route.routeOptions)
                  return parentRouter.emit(nextURL)
                },
              },
              remote: {
                loadAsync: (options) =>
                  remote.loadAsync({ ...options, url: routePath }),
                loadEffect: (options) =>
                  remote.loadEffect({ ...options, url: routePath }),
                loadAsync2: remote.loadAsync2,
                loadEffect2: remote.loadEffect2,
              },
              toasts: {
                showToast: toastEmitter.showToast,
              },
            })
          },
        })
      })
    },
  })
}

function makeRouteErrorProgram({
  error,
}: {
  error: Error
}): Program<unknown, unknown, ReactNode> {
  return {
    init: [undefined],
    update: () => [undefined],
    view() {
      return (
        <Card>
          <CardSection>
            <Heading>{error.message}</Heading>
          </CardSection>
        </Card>
      )
    },
  }
}

type RouteProgramOptions = {
  route: RenderedRoute
  routeOptions: {}
  remote: Remote
  router: RouterWithLinkAndReload
  routeEmitter: RouteEmitter
  toasts: ToastReporter
}

function makeRouteProgram({
  route,
  routeOptions,
  remote,
  router,
  routeEmitter,
  toasts,
}: RouteProgramOptions) {
  const { title, widgets } = route
  return batchPrograms(
    widgets.map((widget, index) =>
      makeWidgetProgram({
        widget,
        widgetIndex: index,
        routeOptions,
        remote,
        router,
        routeEmitter,
        toasts,
      })
    ),
    (widgetViews) => (
      <DocumentTitle title={title}>
        {widgetViews.map((widgetView, index) => (
          <Fragment key={widgets[index]!.key}>{widgetView()}</Fragment>
        ))}
      </DocumentTitle>
    )
  )
}
