import VendorDatePicker from 'react-datepicker'
import { LabeledFieldProps, SandwichLabeledField } from './field'
import 'react-datepicker/dist/react-datepicker.css'

type DatePickerProps = {
  value: number | undefined
  onValue: (date: number | undefined) => void
  autoFocus: boolean
  isEnabled: boolean
  isRequired: boolean
}

export function DatePicker(props: DatePickerProps) {
  const { value, onValue, autoFocus, isEnabled, isRequired } = props

  return (
    <VendorDatePicker
      {...{
        autoFocus,
        disabled: !isEnabled,
        required: isRequired,
        selected: value === undefined ? null : new Date(value),
        onChange(date) {
          onValue(date === null ? undefined : Math.round(+date))
        },
      }}
    />
  )
}

export function DateField(props: DatePickerProps & LabeledFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...dateFieldProps } =
    props
  return (
    <SandwichLabeledField {...props}>
      <DatePicker {...dateFieldProps} />
    </SandwichLabeledField>
  )
}
