import React from 'react'
import { TextField } from '../../../../views/text-field'

const init = () => ({
  after: '',
  before: '',

  afterDate: null,
  beforeDate: null
})

const extract = (model) =>
  [
    model.afterDate && { op: 'gt', value: model.afterDate.valueOf() },
    model.beforeDate && { op: 'lt', value: model.beforeDate.valueOf() }
  ].filter((x) => x)

const restore = (model, flatFilters) => {
  let afterDate = null
  let beforeDate = null
  flatFilters.forEach((filter) => {
    const date = new Date(parseInt(filter.value, 10))
    if (!isFinite(date)) {
      return
    }

    switch (filter.op) {
      case 'gt':
        afterDate = date
        break
      case 'lt':
        beforeDate = date
        break
      default:
    }
  })

  return {
    ...model,
    afterDate,
    beforeDate,

    after: afterDate ? afterDate.toISOString() : model.after,
    before: beforeDate ? beforeDate.toISOString() : model.before
  }
}

// TODO: Actually have a real date picker
function DateField ({ onValue, ...props }) {
  return (
    <TextField
      {...{
        ...props,
        onValue (text) {
          const parsedDate = new Date(text)
          const date = isFinite(parsedDate) ? parsedDate : null
          onValue({ text, date })
        }
      }}
    />
  )
}

function view (model, onValue, viewOptions) {
  return (
    <>
      <DateField
        {...{
          title: viewOptions.afterLabel,
          value: model.after,
          isEnabled: model.isEnabled,
          autoFocus: model.autoFocus,
          onValue ({ text, date }) {
            onValue({
              ...model,
              after: text,
              afterDate: date
            })
          }
        }}
      />
      <DateField
        {...{
          title: viewOptions.beforeLabel,
          value: model.before,
          isEnabled: model.isEnabled,
          onValue ({ text, date }) {
            onValue({
              ...model,
              before: text,
              beforeDate: date
            })
          }
        }}
      />
    </>
  )
}

export const program = { init, view, extract, restore }
