import React, { PropsWithChildren, RefObject } from 'react'

type ClickGroupProps = PropsWithChildren<{ onOutsideClick: () => void }>

export class ClickGroup extends React.Component<ClickGroupProps> {
  clickHandler: undefined | ((event: MouseEvent) => void)
  container: RefObject<HTMLDivElement>

  constructor(props: ClickGroupProps) {
    super(props)

    this.container = React.createRef()
  }

  override componentDidMount() {
    this.addListener(this.props.onOutsideClick)
  }

  override componentDidUpdate() {
    this.removeListener()
    this.addListener(this.props.onOutsideClick)
  }

  override componentWillUnmount() {
    this.removeListener()
  }

  addListener(callback: () => void) {
    if (!callback) {
      return
    }

    this.clickHandler = (event) => {
      const container = this.container.current
      const target = event.target

      const isOutsideClick =
        container &&
        target instanceof Node &&
        !container.contains(target) &&
        document.contains(target)

      if (isOutsideClick) {
        callback()
      }
    }

    // HISTORY: Listening on `window`, click events causing <ClickGroup>
    // mounts would sometimes arrive late, causing them to instantly close.
    document.addEventListener('click', this.clickHandler, { passive: true })
  }

  removeListener() {
    if (this.clickHandler) {
      document.removeEventListener('click', this.clickHandler)
    }
  }

  override render() {
    const { children } = this.props
    return (
      <div
        {...{
          ref: this.container,
          className: 'ClickGroup__class_for_debugging',
          children,
        }}
      />
    )
  }
}
