import React, { ChangeEvent, createRef, ReactNode } from 'react'
import styled from 'styled-components'
import {
  Title,
  Description,
  LabeledFieldProps,
  SideLabeledField,
} from './field'

const FieldList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0.5em 0px;
`

const FieldItem = styled.li``

type BaseCheckInputProps = {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
  required: boolean
  indeterminate?: boolean
  autoFocus?: boolean
}

const RawCheckbox = styled.input`
  font-size: 1rem;
  margin: 0px;

  &:disabled {
    cursor: not-allowed;
  }
`

class BaseCheckbox extends React.Component<BaseCheckInputProps> {
  ref: React.RefObject<HTMLInputElement>

  constructor(props: BaseCheckInputProps) {
    super(props)
    this.ref = createRef()
  }

  override componentDidMount() {
    this.updateIndeterminate()
  }

  override componentDidUpdate() {
    this.updateIndeterminate()
  }

  updateIndeterminate() {
    const input = this.ref.current
    const { indeterminate } = this.props
    const normalizedIndeterminate = indeterminate || false

    if (input && input.indeterminate !== normalizedIndeterminate) {
      input.indeterminate = normalizedIndeterminate
    }
  }

  override render() {
    return (
      <RawCheckbox
        {...{
          ...this.props,
          type: 'checkbox',
          ref: this.ref,
        }}
      />
    )
  }
}

type CheckboxProps = {
  value: boolean
  isEnabled: boolean

  onValue?: (newValue: boolean) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
  indeterminate?: boolean
  autoFocus?: boolean
}

export class Checkbox extends React.Component<CheckboxProps> {
  override render() {
    const {
      value,
      onValue,
      onChange,
      isEnabled,
      isRequired,
      indeterminate,
      autoFocus,
    } = this.props

    return (
      <BaseCheckbox
        {...{
          checked: value,
          disabled: !isEnabled,
          required: !!isRequired,
          indeterminate,
          autoFocus,
          onChange(event) {
            if (onChange) {
              onChange(event)
            }

            if (onValue) {
              const input = event.target
              onValue(input.checked)
            }
          },
        }}
      />
    )
  }
}

type CheckboxFieldProps = CheckboxProps & LabeledFieldProps

export function CheckBoxField(props: CheckboxFieldProps) {
  const { title, description, noPadding, noLabelWrap, ...checkProps } = props

  return (
    <SideLabeledField
      {...{
        title,
        description,
        isEnabled: checkProps.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <Checkbox {...checkProps} />
    </SideLabeledField>
  )
}

type CheckboxListOption = {
  value: string
  title: string
  isEnabled: boolean
  description?: string
  selected: boolean
}

// NOTE: Does not use <Field> because it doesn't make sense to wrap in Label ever
// TODO: Implement a <Fieldset> component for these lists of inputs
type CheckboxListFieldProps = {
  title: ReactNode
  description: ReactNode
  isEnabled: boolean
  options: CheckboxListOption[]
  onCheck: (change: { value: string; isSelected: boolean }) => void
}

export class CheckboxListField extends React.Component<CheckboxListFieldProps> {
  override render() {
    const { title, description, isEnabled, options, onCheck } = this.props
    return (
      <>
        <div>
          <Title {...{ isEnabled }}>{title}</Title>
          {description && <Description>{description}</Description>}
        </div>
        <FieldList>
          {options.map((option) => (
            <FieldItem key={option.value}>
              <CheckBoxField
                {...{
                  title: option.title,
                  value: option.selected,
                  isEnabled: option.isEnabled && isEnabled,
                  onValue(isSelected) {
                    onCheck({ value: option.value, isSelected })
                  },
                }}
              />
            </FieldItem>
          ))}
        </FieldList>
      </>
    )
  }
}

type RadioProps = {
  value: boolean
  onValue: (checked: boolean) => void
  isEnabled: boolean
  isRequired?: boolean
}

export class Radio extends React.Component<RadioProps> {
  override render() {
    const { value, onValue, isEnabled, isRequired } = this.props
    return (
      <input
        {...{
          type: 'radio',
          checked: value,
          disabled: !isEnabled,
          required: isRequired,
          onChange(event) {
            onValue(event.target.checked)
          },
        }}
      />
    )
  }
}

type RadioFieldProps = RadioProps & LabeledFieldProps

function RadioField(props: RadioFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...radioProps } = props
  return (
    <SideLabeledField
      {...{
        title,
        description,
        isEnabled: props.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <Radio {...radioProps} />
    </SideLabeledField>
  )
}

type RadioListOption = {
  value: string
  title: string
  isEnabled: boolean
  description?: string
}

// NOTE: Does not use <Field> because it doesn't make sense to wrap in Label ever
// TODO: Implement a <Fieldset> component for these lists of inputs
type RadioListFieldProps = {
  title: ReactNode
  description: ReactNode
  isEnabled: boolean
  isRequired: boolean
  options: RadioListOption[]
  value: string | undefined
  onValue: (value: string) => void
}

export function RadioListField(props: RadioListFieldProps) {
  const { title, description, isEnabled, isRequired, options, value, onValue } =
    props

  return (
    <>
      <Title {...{ isEnabled }}>{title}</Title>
      <Description>{description}</Description>
      <FieldList>
        {options.map((option) => (
          <FieldItem key={option.value}>
            <label>
              <RadioField
                {...{
                  title: option.title,
                  value: value === option.value,
                  isEnabled: option.isEnabled && isEnabled,
                  isRequired,
                  onValue() {
                    onValue(option.value)
                  },
                }}
              />
            </label>
          </FieldItem>
        ))}
      </FieldList>
    </>
  )
}

// const Content = styled.div``

// export class AttributeField extends React.Component {
//   override render() {
//     const { title, content } = this.props
//     return (
//       <>
//         <Title>{title}</Title>
//         <Content>{content}</Content>
//       </>
//     )
//   }
// }
