import { PropsWithChildren, ReactChild, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Paragraph, Link, ExternalLink, Code } from '../../../views/card'
import { Badge, BadgeColor } from '../../../views/badge'
import { Secret } from './secret'
import { Button } from '../../../views/button'
import { Remote } from '../remote'
import { absurd, must, shouldBeImpossible } from '../../../util/exhaustiveness'
import { RenderedValue } from 'center/src/ui-bridge'
import { FieldValue } from 'center/src/ui/field'

const PreformattedText = styled.pre`
  white-space: pre-wrap;
`

const Time = styled.time`
  white-space: nowrap;
`

const ImageSlot = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 100%;
  margin-right: 20px;
`

const FillImage = styled.img`
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 100%;
`

const ImageErrorState = styled.div`
  background-color: #f8fafe;
  margin-right: 20px;
  min-height: 100px;
  position: relative;
`

const ImageErrorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageErrorFrame = styled.div`
  padding: 30px;
`

const ImageError = styled.div`
  text-align: center;

  ${Paragraph} {
    margin-bottom: 10px;
  }
`

type ImageLoadErrorProps = {
  width: number | undefined
  height: number | undefined
  onReload: () => void
}

const ImageLoadError = ({ width, height, onReload }: ImageLoadErrorProps) => {
  const error = (
    <ImageError>
      <Paragraph>Failed to load image</Paragraph>
      <Button
        {...{
          title: 'Reload image',
          isEnabled: true,
          onClick: onReload,
        }}
      />
    </ImageError>
  )

  if (width && height) {
    return (
      <ImageErrorState>
        <FillImage
          {...{
            // transparent image in order to match the size of the missing image
            src: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"></svg>`,
            width,
            height,
          }}
        />
        <ImageErrorOverlay>{error}</ImageErrorOverlay>
      </ImageErrorState>
    )
  }

  return (
    <ImageErrorState>
      <ImageErrorFrame>{error}</ImageErrorFrame>
    </ImageErrorState>
  )
}

const ImageDetails = (props: PropsWithChildren<{}>) => (
  <table>
    <tbody>{props.children}</tbody>
  </table>
)

const ImageDetail = ({ name, value }: { name: string; value: string }) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{value}</td>
    </tr>
  )
}

type ImageDisplayDetail = {
  key: string
  name: string
  value: string
}

type ImageDisplayProps = {
  url: string
  altText: string
  width: number
  height: number
  details: ImageDisplayDetail[]
}

export const ImageDisplay = ({
  url,
  altText,
  width,
  height,
  details,
}: ImageDisplayProps) => {
  const [didLoadError, setDidLoadError] = useState(false)

  return (
    <ImageSlot>
      {didLoadError ? (
        <ImageLoadError
          {...{
            width,
            height,
            onReload: () => setDidLoadError(false),
          }}
        />
      ) : (
        <Image
          {...{
            src: url,
            alt: altText,
            width,
            height,
            onError() {
              setDidLoadError(true)
            },
          }}
        />
      )}
      {details.length > 0 && (
        <ImageDetails>
          {details.map((detail) => (
            <ImageDetail {...detail} />
          ))}
        </ImageDetails>
      )}
    </ImageSlot>
  )
}

type ValueLink =
  | { type: 'external'; url: string }
  | { type: 'local'; url: string }

type LinkProps = {
  link: ValueLink
  makeLocalLink: (href: string) => string
  children: ReactChild
}

function Linkable({ link, makeLocalLink, children }: LinkProps) {
  switch (link.type) {
    case 'external':
      return <ExternalLink href={link.url}>{children}</ExternalLink>
    case 'local':
      return <Link href={makeLocalLink(link.url)}>{children}</Link>
    default:
      absurd(link)
  }
}

type TextValue = {
  type: 'text'
  text: string | undefined
  link: ValueLink | undefined
  isPreformatted: boolean
  optional: string
}

type ReferenceValue = Omit<TextValue, 'type'> & { type: 'reference' }

type NumberValue = {
  type: 'number'
  // A string to avoid formatting issues over the wire
  number: string | undefined
  link: ValueLink | undefined
  optional: string
}

type DateValue = {
  type: 'date'
  optional: string
  link: ValueLink | undefined

  rawDate: string
  completeDate: string
  relativeDate: string
}

type ImageValue = {
  type: 'image'
  optional: string
  link: ValueLink | undefined
  result:
    | { type: 'error'; message: string | undefined }
    | {
        type: 'image'
        url: string
        altText: string
        width: number
        height: number
        details: ImageDisplayDetail[]
      }
    | undefined
}

type EnumValue = {
  type: 'enum'
  optional: string
  style: 'badge' | 'paragraph'
  title: string
  color: BadgeColor
}

type SecretValue = {
  type: 'secret'
  optional: string
  secret: { id: string; state: string; preview: string }

  title: string
  description: string
  isMultipleLines: boolean
}

// TODO: Remove once we have the new value system on par
export type ValueSpec =
  | TextValue
  | ReferenceValue
  | NumberValue
  | DateValue
  | ImageValue
  | EnumValue
  | SecretValue

type ValueViewC = {
  fieldValue: FieldValue
  valueType: RenderedValue
  makeLocalLink: (href: string) => string
  remote: Remote
}

export function makeValueView({
  fieldValue,
  valueType,
  makeLocalLink,
  remote,
}: ValueViewC): ReactNode {
  switch (fieldValue.type) {
    case 'text': {
      if (valueType.kind.type !== 'text') {
        shouldBeImpossible(valueType.kind, 'should match field value')
      }

      // case 'reference': {
      if (fieldValue.text == null) {
        return <Paragraph>{valueType.optional}</Paragraph>
      }

      if (valueType.kind.preformatted) {
        return (
          <Code>
            <PreformattedText>{fieldValue.text}</PreformattedText>
          </Code>
        )
      }

      if (fieldValue.link) {
        return (
          <Paragraph>
            <Linkable {...{ link: fieldValue.link, makeLocalLink }}>
              {fieldValue.text}
            </Linkable>
          </Paragraph>
        )
      }

      return <Paragraph>{fieldValue.text}</Paragraph>
    }

    case 'integer': {
      if (fieldValue.number == null) {
        return <Paragraph>{valueType.optionalText}</Paragraph>
      }

      if (fieldValue.link) {
        return (
          <Paragraph>
            <Linkable {...{ link: fieldValue.link, makeLocalLink }}>
              {fieldValue.number}
            </Linkable>
          </Paragraph>
        )
      }

      return <Paragraph>{fieldValue.number}</Paragraph>
    }
    case 'date': {
      return (
        <Time title={fieldValue.completeDate} dateTime={fieldValue.rawDate}>
          {fieldValue.link ? (
            <Linkable {...{ link: fieldValue.link, makeLocalLink }}>
              {fieldValue.relativeDate}
            </Linkable>
          ) : (
            fieldValue.relativeDate
          )}
        </Time>
      )
    }
    /*
    case 'image': {
      if (!fieldValue.result) {
        return <Paragraph>{valueType.optional}</Paragraph>
      }

      const { result } = fieldValue
      switch (result.type) {
        case 'error': {
          return <Paragraph>{result.message}</Paragraph>
        }
        case 'image': {
          const { url, altText, width, height, details } = result
          return <ImageDisplay {...{ url, altText, width, height, details }} />
        }
        default:
          return absurd(result)
      }
    }
    */
    case 'enum': {
      const { kind } = valueType
      if (kind.type !== 'enum') {
        shouldBeImpossible(valueType.kind, 'should match field value')
      }

      const title = must(
        kind.values.find((v) => v.value === fieldValue.value)
      ).title

      const { style } = kind
      switch (style) {
        case 'badge': {
          const color =
            kind.values.find((v) => v.value === fieldValue.value)?.color ||
            'gray'

          return <Badge label={title} color={color} />
        }
        case 'plain': {
          return <Paragraph>{title}</Paragraph>
        }
        default:
          return absurd(style)
      }
    }
    case 'secret': {
      if (valueType.kind.type !== 'secret') {
        shouldBeImpossible(valueType.kind, 'should match field value')
      }
      const { title, description } = valueType
      const { isMultipleLines } = valueType.kind

      return (
        <Secret
          {...{
            value: fieldValue.secret,
            remote,
            viewOptions: {
              title: title,
              description,
              isMultipleLines,
            },
          }}
        />
      )
    }
    default:
      absurd(fieldValue)
  }
}
