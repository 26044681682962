import { mapEffect } from 'center/compiled/util/raj-compose'
import {
  withSubscriptions,
  mapSubscription,
} from '../../../util/raj-subscription'
import {
  CardSection,
  Paragraph,
  Card,
  Section,
  CardHeader,
  Heading,
} from '../../../views/card'
import { Banner } from '../../../views/banner'
import { Split, SplitPriority } from '../../../views/spacing'
import { Table, TableColumn } from '../../../views/table'
import { MainProvidedOptions, withActionModalOverlay } from './action-overlay'
import { Button, ButtonSet, ButtonSection } from '../../../views/button'
import { makeValueView } from './value'
import { Checkbox } from '../../../views/check-field'
import { Icon } from '../../../views/icon'
import {
  createFilterModel,
  clearFilter,
  QuickFilter,
  FullFilter,
  extractFilters,
  hasFiltersSet,
} from './filter'
import { ModalOverlay } from '../../../views/overlay'
import styled, { css } from 'styled-components'
import { Change, Dispatch, Effect } from 'center/compiled/util/raj'
import { Remote } from '../remote'
import { absurd } from '../../../util/exhaustiveness'
import { RouterWithLinkAndReload } from '.'
import { ToastReporter } from '../toast'
import { RouterWithLink } from '../side'
import { Route, RouteEmitter } from '../route'
import {
  ActionPreview,
  ItemWithId,
  ListMetadata,
  ListPagination,
  RenderedList,
  RenderedListColumn,
} from 'center/src/ui-bridge'

export type ListWidget = {
  key: string
  type: 'list'
  label: string
  empty: string
  actions: Action[]
  hasItemActions: boolean
  columns: RenderedListColumn[]
  items: ItemWithId[]
  metadata: ListMetadata
  pagination: ListPagination
}

type FullListProgramOptions = {
  widget: RenderedList
  routeOptions: Record<string, string>
  remote: Remote
  router: RouterWithLinkAndReload
  routeEmitter: RouteEmitter
  toasts: ToastReporter
}

export function makeListProgram({
  widget,
  routeOptions,
  remote,
  router,
  routeEmitter,
  toasts,
}: FullListProgramOptions) {
  return withActionModalOverlay({
    remote,
    router,
    toasts,
    makeProgram: (main) =>
      makeTableProgram({
        widget,
        routeOptions,
        router,
        routeEmitter,
        remote,
        main,
      }),
  })
}

type Action = { id: string; label: string }
type FilterHandler = (result: unknown, model: unknown) => unknown

type Msg =
  | { type: 'update_state'; actionKey: string }
  | { type: 'select_all' }
  | { type: 'select_item'; itemId: string; isSelected: boolean }
  | { type: 'select_item_range'; itemId: string }
  | {
      type: 'set_actions'
      result: { type: 'error' } | { type: 'actions'; actions: ActionPreview[] }
    }
  | { type: 'set_route'; currentRoute: string }
  | { type: 'load_previous' }
  | { type: 'load_next' }
  | { type: 'close_quick_filter' }
  | { type: 'clear_quick_filter' }
  | { type: 'open_quick_filter'; filterKey: string }
  | { type: 'submit_quick_filter' }
  | { type: 'open_full_filter' }
  | { type: 'close_full_filter' }
  | { type: 'clear_full_filter' }
  | { type: 'submit_full_filter' }
  | {
      type: 'filter_change'
      change: {
        model: unknown
        effectHandler:
          | { handle: FilterHandler; effect: Effect<unknown> }
          | undefined
      }
    }
  | { type: 'filter_effect'; result: unknown }
  | { type: 'dismiss_cursor_error' }

type Model = {
  items: ItemWithId[]
  metadata: ListMetadata
  pagination: ListPagination
  selection: string[]
  lastSelectedItem: string | undefined

  actions: ActionPreview[]

  filtering: boolean
  filterKey: string | undefined
  filterModel: unknown
  filterHandler: FilterHandler | undefined

  isLoading: boolean
  loadError: { message: string } | undefined

  currentRoute: string
}

function getSectionItemsBetween<Item>(items: Item[], a: Item, b: Item): Item[] {
  const indexA = items.indexOf(a)
  const indexB = items.indexOf(b)
  const start = Math.min(indexA, indexB)
  const end = Math.max(indexA, indexB)

  return items.slice(start, end + 1)
}

export function keepPresentKeys<A>(
  obj: Record<string, A | undefined>
): Record<string, A> {
  const newObject: Record<string, A> = {}
  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      newObject[key] = value
    }
  }
  return newObject
}

type TableProgramOptions = {
  widget: RenderedList
  routeOptions: Record<string, string>
  remote: Remote
  router: RouterWithLink
  routeEmitter: RouteEmitter
  main: MainProvidedOptions
}

export function makeTableProgram({
  widget,
  routeOptions,
  router,
  routeEmitter,
  remote,
  main,
}: TableProgramOptions) {
  /*
  function loadSelectionActions(
    tableId: string,
    selection: string[]
  ): Effect<{ type: 'error' } | { type: 'actions'; actions: Action[] }> {
    return remote.loadEffect({
      id: tableId,
      action: 'renderActions',
      params: { selection },
    }) as any
  }
  */

  const init: Change<Msg, Model> = [
    {
      actions: widget.actions,
      items: widget.items,
      metadata: widget.metadata,
      pagination: widget.pagination,
      selection: main.selection,
      lastSelectedItem: main.lastSelectedItem,

      filterModel: createFilterModel(
        widget.columns,
        routeOptions,
        remote.loadEffect
      ),
      filterHandler: undefined,
      filterKey: undefined,
      filtering: false,

      isLoading: false,
      loadError: undefined,
      currentRoute: '',
    },
  ]

  const loadWithFilters = (model: Model): Change<Msg, Model> => {
    const filters = extractFilters(model.filterModel)
    const newFilterRoute: Route = {
      route: model.currentRoute,
      routeOptions: keepPresentKeys({
        'starting-after': routeOptions['starting-after'],
        'ending-before': routeOptions['ending-before'],
        ...filters,
      }),
    }

    return [model, routeEmitter.emit(newFilterRoute)]
  }

  const update = (msg: Msg, model: Model): Change<Msg, Model> => {
    switch (msg.type) {
      case 'update_state': {
        const action = model.actions.find(
          (action) => action.key === msg.actionKey
        )!
        return [
          model,
          () =>
            main.updateState({
              action,
              selection: model.selection,
              lastSelectedItem: undefined,
            }),
        ]
      }
      case 'select_all': {
        const selection = model.selection.length
          ? []
          : model.items.map((item) => item.id)
        return [
          model,
          () =>
            main.updateState({
              action: undefined,
              selection,
              lastSelectedItem: undefined,
            }),
        ]
      }
      case 'select_item': {
        const { itemId, isSelected } = msg
        const { selection: oldSelection } = model
        const selection = isSelected
          ? Array.from(new Set(oldSelection.concat(itemId)))
          : oldSelection.filter((i) => i !== itemId)
        const update = isSelected
          ? { action: undefined, selection, lastSelectedItem: itemId }
          : { action: undefined, selection, lastSelectedItem: undefined }

        return [model, () => main.updateState(update)]
      }
      case 'select_item_range': {
        const { itemId } = msg
        const { lastSelectedItem } = model
        const selection = lastSelectedItem
          ? getSectionItemsBetween(
              model.items.map((i) => i.id),
              lastSelectedItem,
              itemId
            )
          : [itemId]

        return [
          model,
          () =>
            main.updateState({
              action: undefined,
              selection,
              lastSelectedItem,
            }),
        ]
      }
      case 'set_actions': {
        const { result } = msg
        return result.type === 'error'
          ? [model]
          : [{ ...model, actions: result.actions }]
      }
      case 'set_route': {
        const { currentRoute } = msg
        return [{ ...model, currentRoute }]
      }

      // TODO: these operate under the assumption this is the only page widget that
      // controls the query string; we'll need to fix this to support multiple tables
      case 'load_previous': {
        const { previousPageCursor } = model.metadata
        if (!previousPageCursor) {
          return [model]
        }

        const nextRoute: Route = {
          route: model.currentRoute,
          routeOptions: keepPresentKeys({
            ...routeOptions,
            cursor: previousPageCursor,
          }),
        }

        return [model, routeEmitter.emit(nextRoute)]
      }
      case 'load_next': {
        const { nextPageCursor } = model.metadata
        if (!nextPageCursor) {
          return [model]
        }

        const nextRoute: Route = {
          route: model.currentRoute,
          routeOptions: keepPresentKeys({
            ...routeOptions,
            cursor: nextPageCursor,
          }),
        }

        return [model, routeEmitter.emit(nextRoute)]
      }

      case 'open_quick_filter': {
        const { filterKey } = msg
        return [{ ...model, filterKey }]
      }
      case 'close_quick_filter': {
        return [{ ...model, filterKey: undefined }]
      }
      case 'clear_quick_filter': {
        const filterModel = clearFilter(model.filterModel, model.filterKey)
        return loadWithFilters({ ...model, filterModel, filterKey: undefined })
      }
      case 'submit_quick_filter': {
        return loadWithFilters({ ...model, filterKey: undefined })
      }

      case 'open_full_filter': {
        return [{ ...model, filtering: true }]
      }
      case 'close_full_filter': {
        return [{ ...model, filtering: false }]
      }
      case 'clear_full_filter': {
        return loadWithFilters({
          ...model,
          filterModel: createFilterModel(
            widget.columns,
            routeOptions,
            remote.loadEffect
          ),
        })
      }
      case 'submit_full_filter': {
        return loadWithFilters(model)
      }

      case 'filter_change': {
        const { model: filterModel, effectHandler: filterEffectHandler } =
          msg.change
        const newModel = { ...model, filterModel }

        if (!filterEffectHandler) {
          return [newModel]
        }

        return [
          { ...newModel, filterHandler: filterEffectHandler.handle },
          mapEffect(
            filterEffectHandler.effect,
            (result) => ({ type: 'filter_effect', result } as Msg)
          ),
        ]
      }
      case 'filter_effect': {
        const { result } = msg
        const { filterModel, filterHandler } = model
        if (!filterHandler) {
          return [model]
        }

        return [
          {
            ...model,
            filterModel: filterHandler(result, filterModel),
            filterHandler: undefined,
          },
        ]
      }
      case 'dismiss_cursor_error': {
        const cursorLessRoute: Route = {
          route: model.currentRoute,
          routeOptions: keepPresentKeys(
            Object.assign(routeOptions, {
              cursor: undefined,
            })
          ),
        }

        return [model, routeEmitter.emit(cursorLessRoute)]
      }
      default:
        return absurd(msg)
    }
  }

  const subscriptions = () => ({
    route: () =>
      mapSubscription(
        router.subscribe(),
        (currentRoute) =>
          ({
            type: 'set_route',
            currentRoute,
          } as Msg)
      ),
  })

  const viewOptions: ViewOptions = {
    label: widget.title,
    emptyText: widget.emptyText,
    loadingText: 'Loading',
    loadErrorText: 'Failed to load',
    columns: widget.columns,
    hasItemActions: widget.actions.some((a) => a.selection),
    remote,
    makeLocalLink: router.link,
  }

  function view(model: Model, dispatch: Dispatch<Msg>) {
    return viewWithOptions(model, dispatch, viewOptions)
  }

  return withSubscriptions({ init, update, subscriptions, view })
}

const FilterColumn = styled.div<{ isFiltered: boolean }>`
  white-space: nowrap;

  ${(props) =>
    props.isFiltered &&
    css`
      color: #065aa3;
    `}
`

const FilterColumnIcon = styled.span`
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
`

const DimmedParagraph = styled(Paragraph)`
  color: #aaa;
  cursor: default;
`

export function Optional({ title }: { title: string }) {
  return <DimmedParagraph title={title}>—</DimmedParagraph>
}

type FilterableColumnHeaderProps = {
  label: string
  isFiltered: boolean
  onClick: () => void
}

const FilterableColumnHeader = ({
  label,
  isFiltered,
  onClick,
}: FilterableColumnHeaderProps) => {
  return (
    <FilterColumn isFiltered={isFiltered}>
      {label}
      <FilterColumnIcon onClick={() => onClick()}>
        <Icon name="filter" />
      </FilterColumnIcon>
    </FilterColumn>
  )
}

type ViewOptions = {
  label: string
  columns: RenderedListColumn[]

  emptyText: string
  loadingText: string
  loadErrorText: string
  hasItemActions: boolean

  makeLocalLink: (href: string) => string
  remote: Remote
}

function viewWithOptions(
  model: Model,
  dispatch: Dispatch<Msg>,
  viewOptions: ViewOptions
) {
  if (model.isLoading) {
    const { loadingText } = viewOptions
    return (
      <Card>
        {headerView(model, dispatch, viewOptions)}
        <CardSection>
          <Paragraph centered>{loadingText}</Paragraph>
        </CardSection>
      </Card>
    )
  }

  if (model.loadError) {
    const { loadErrorText } = viewOptions
    return (
      <Card>
        {headerView(model, dispatch, viewOptions)}
        <CardSection>
          <Banner
            {...{
              color: 'red',
              title: loadErrorText,
              description: model.loadError.message,
            }}
          />
        </CardSection>
      </Card>
    )
  }

  const builtInColumns: TableColumn<ItemWithId>[] = []
  if (viewOptions.hasItemActions) {
    const selectColumn: TableColumn<ItemWithId> = {
      key: '$select',
      alignment: 'left',
      size: 'minimized',
      title: (
        <Checkbox
          {...{
            value:
              model.items.length > 0 &&
              model.selection.length === model.items.length,
            indeterminate:
              model.selection.length > 0 &&
              model.selection.length !== model.items.length,
            onValue() {
              dispatch({ type: 'select_all' })
            },
            isEnabled: model.items.length > 0,
          }}
        />
      ),
      value: (item) => (
        <Checkbox
          {...{
            value: model.selection.includes(item.id),
            onChange(changeEvent) {
              const isSelected = changeEvent.target.checked

              const event = changeEvent.nativeEvent
              if (!(event instanceof MouseEvent)) {
                return
              }

              const msg: Msg =
                model.lastSelectedItem && event.shiftKey
                  ? { type: 'select_item_range', itemId: item.id }
                  : { type: 'select_item', itemId: item.id, isSelected }

              dispatch(msg)
            },
            isEnabled: true,
          }}
        />
      ),
    }
    builtInColumns.push(selectColumn)
  }

  const {
    columns: valueColumns,
    emptyText,
    makeLocalLink,
    remote,
  } = viewOptions
  const itemColumns: TableColumn<ItemWithId>[] = valueColumns.map(
    (column, index) => {
      const isLast: boolean = index + 1 === valueColumns.length
      const alignment = isLast && column.kind.type === 'date' ? 'right' : 'left'

      return {
        key: column.key,
        title: column.filterable ? (
          <FilterableColumnHeader
            {...{
              label: column.title,
              isFiltered: hasFiltersSet(model.filterModel, column.key),
              onClick() {
                dispatch({ type: 'open_quick_filter', filterKey: column.key })
              },
            }}
          />
        ) : (
          column.title
        ),
        value: (item) => {
          const value = item[column.key]
          if (!value) {
            if (column.optional) {
              return <Optional title={column.optionalText} />
            }

            console.error('Value not provided for column', {
              column: column.key,
            })
            return null
          }

          return makeValueView({
            fieldValue: value,
            valueType: column,
            makeLocalLink,
            remote,
          })
        },
        size: column.isPrimary ? 'maximized' : 'auto',
        alignment,
      }
    }
  )

  const columns = builtInColumns.concat(itemColumns)
  return (
    <Card>
      {headerView(model, dispatch, viewOptions)}
      {quickFilterView(model, dispatch)}
      {fullFilterView(model, dispatch)}
      <Section>
        {model.metadata.inputCursorError && (
          <CardSection>
            <Banner
              {...{
                title: model.metadata.inputCursorError,
                color: 'red',
                onDismiss() {
                  dispatch({ type: 'dismiss_cursor_error' })
                },
              }}
            />
          </CardSection>
        )}

        <Table<ItemWithId>
          {...{
            items: model.items,
            itemKey(item) {
              return item.id
            },
            emptyText: emptyText,
            hasHeader: true,
            columns,
          }}
        />
      </Section>
      {footerView(model, dispatch)}
    </Card>
  )
}

function isActionSelectionMatched(
  inputSelection: Model['selection'],
  actionSelection: ActionPreview['selection']
): boolean {
  switch (actionSelection.type) {
    case 'none': {
      return inputSelection.length === 0
    }
    case 'exact': {
      return inputSelection && inputSelection.length === 1
    }
    case 'range': {
      return (
        inputSelection &&
        inputSelection.length >= actionSelection.minimum &&
        inputSelection.length <= actionSelection.maximum
      )
    }
    default:
      absurd(actionSelection)
  }
}

function headerView(
  model: Model,
  dispatch: Dispatch<Msg>,
  viewOptions: ViewOptions
) {
  const { label, columns } = viewOptions
  const selectionActions = model.actions
  const showSelectionActions = selectionActions.length > 0
  const filterable = columns.some((c) => c.filterable)

  const { selection } = model

  const filterButton = (
    <Button
      {...{
        title: 'Filter…',
        isEnabled: true,
        onClick() {
          dispatch({ type: 'open_full_filter' })
        },
      }}
    />
  )

  if (showSelectionActions) {
    return (
      <>
        <CardHeader title={label} />
        <ButtonSection alternativeButtons={filterable && filterButton}>
          {showSelectionActions &&
            selectionActions.map((action) => {
              const isEnabled = isActionSelectionMatched(
                selection,
                action.selection
              )

              return (
                <Button
                  {...{
                    key: action.key,
                    title: action.buttonText,
                    isEnabled,
                    onClick() {
                      dispatch({ type: 'update_state', actionKey: action.key })
                    },
                  }}
                />
              )
            })}
        </ButtonSection>
      </>
    )
  }

  if (filterable) {
    return (
      <CardSection>
        <Split verticalCenter>
          <Heading>{label}</Heading>
          <SplitPriority />
          <ButtonSet>{filterButton}</ButtonSet>
        </Split>
      </CardSection>
    )
  }

  return <CardHeader title={label} />
}

const filterViewOptions = {
  fullFilter: 'Filter',

  clearButton: 'Clear',
  cancelButton: 'Cancel',
  submitButton: 'Submit',

  // enum
  matchesLabel: 'Matches',

  // date
  afterLabel: 'After',
  beforeLabel: 'Before',

  // number
  greaterThanLabel: 'Greater than',
  lessThanLabel: 'Less than',

  // reference
  searchLoadingText: 'Loading...',
  searchResultsEmpty: 'There are no results.',

  // text
  equalToLabel: 'Equal to',
} as const

function quickFilterView(model: Model, dispatch: Dispatch<Msg>) {
  if (!model.filterKey) {
    return null
  }

  return (
    <ModalOverlay onDismiss={() => dispatch({ type: 'close_quick_filter' })}>
      <QuickFilter
        {...{
          isEnabled: true,
          filterKey: model.filterKey,
          viewOptions: filterViewOptions,
          value: model.filterModel,
          onValue(change: any) {
            dispatch({ type: 'filter_change', change })
          },
          onClear() {
            dispatch({ type: 'clear_quick_filter' })
          },
          onCancel() {
            dispatch({ type: 'clear_quick_filter' })
          },
          onSubmit() {
            dispatch({ type: 'submit_quick_filter' })
          },
        }}
      />
    </ModalOverlay>
  )
}

function fullFilterView(model: Model, dispatch: Dispatch<Msg>) {
  if (!model.filtering) {
    return null
  }

  return (
    <ModalOverlay onDismiss={() => dispatch({ type: 'close_full_filter' })}>
      <FullFilter
        {...{
          isEnabled: true,
          viewOptions: filterViewOptions,
          value: model.filterModel,
          onValue(change: any) {
            dispatch({ type: 'filter_change', change })
          },
          onClear() {
            dispatch({ type: 'clear_full_filter' })
          },
          onCancel() {
            dispatch({ type: 'clear_full_filter' })
          },
          onSubmit() {
            dispatch({ type: 'submit_full_filter' })
          },
        }}
      />
    </ModalOverlay>
  )
}

function footerView(model: Model, dispatch: Dispatch<Msg>) {
  const { metadata } = model
  const { total, nextPageCursor, previousPageCursor } = metadata
  return (
    <CardSection>
      <Split verticalCenter>
        <Paragraph>{total} results</Paragraph>

        <ButtonSet>
          <Button
            {...{
              title: 'Previous',
              isEnabled: !!previousPageCursor,
              onClick() {
                dispatch({ type: 'load_previous' })
              },
            }}
          />
          <Button
            {...{
              title: 'Next',
              isEnabled: !!nextPageCursor,
              onClick() {
                dispatch({ type: 'load_next' })
              },
            }}
          />
        </ButtonSet>
      </Split>
    </CardSection>
  )
}
