import { RenderedItemNotFound } from 'center/src/ui-bridge'
import { viewOnlyProgram } from '../view-only-program'
import { Card, CardSection, Heading } from '../../../views/card'

export function makeItemNotFoundProgram({}: { widget: RenderedItemNotFound }) {
  return viewOnlyProgram(() => (
    <Card>
      <CardSection>
        <Heading>Resource not found</Heading>
      </CardSection>
    </Card>
  ))
}
