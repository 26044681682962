import { Dispatch, Disposer } from 'center/compiled/util/raj'
import { Subscription } from '../../util/raj-subscription'

export type Msg =
  | { type: 'success' }
  | { type: 'upload_cancelled' }
  | {
      type: 'upload_progress'
      uploadedByteCount: number
      totalByteCount: number
    }
  | { type: 'upload_complete' }
  | {
      type: 'error'
      message:
        | 'request_error'
        | 'unexpected_server_response'
        | 'unknown_server_error'
        | 'unexpected_server_response'
    }

function parseJsonResponse(jsonReply: string): Msg {
  let data
  try {
    data = JSON.parse(jsonReply)
  } catch (error) {
    return { type: 'error', message: 'unexpected_server_response' }
  }

  if (data && data.type === 'ok') {
    return { type: 'success' }
  }

  if (data && data.type === 'error') {
    return {
      type: 'error',
      message: data.message || 'unknown_server_error',
    }
  }

  return { type: 'error', message: 'unexpected_server_response' }
}

function uploadFile(
  uploadRequestURL: string,
  file: File,
  dispatch: Dispatch<Msg>
): Disposer {
  const formData = new window.FormData()
  formData.append('file', file)

  const request = new window.XMLHttpRequest()

  request.upload.addEventListener('progress', (event) => {
    if (event.lengthComputable) {
      dispatch({
        type: 'upload_progress',
        uploadedByteCount: event.loaded,
        totalByteCount: event.total,
      })
    }
  })

  let cancelled = false
  request.upload.addEventListener('abort', () => {
    if (!cancelled) {
      dispatch({ type: 'upload_cancelled' })
    }
  })

  request.upload.addEventListener('load', () => {
    dispatch({ type: 'upload_complete' })
  })

  request.addEventListener('error', () => {
    dispatch({ type: 'error', message: 'request_error' })
  })

  request.addEventListener('load', () => {
    const reply = parseJsonResponse(request.responseText)
    dispatch(reply)
  })

  request.open('POST', uploadRequestURL)
  request.send(formData)

  const cancel = () => {
    cancelled = true
    request.abort()
  }

  return cancel
}

export function makeFileUploadSubscription(
  uploadRequestURL: string,
  file: File
): Subscription<Msg> {
  let cancel: Disposer
  return {
    effect(dispatch) {
      cancel = uploadFile(uploadRequestURL, file, dispatch)
    },
    cancel() {
      if (cancel) {
        cancel()
      }
    },
  }
}
