import { Subscription } from './raj-subscription'

type Options<Val> = { onListeningChange?: (isListening: boolean) => void } & (
  | { shouldPrime: false }
  | {
      shouldPrime: true
      initialValue: Val
    }
)

type Listener<Val> = {
  (value: Val): void
}

export type Emitter<Val> = {
  emit(value: Val): void
  subscribe: () => Subscription<Val>
}

export function createEmitter<Val>(options: Options<Val>): Emitter<Val> {
  const { shouldPrime, onListeningChange } = options
  let currentValue: Val
  let listeners: Listener<Val>[] = []

  if (options.shouldPrime) {
    currentValue = options.initialValue
  }

  return {
    emit: (value) => {
      currentValue = value
      listeners.forEach((listener) => listener(currentValue))
    },

    subscribe() {
      let listener: Listener<Val> | undefined
      return {
        effect(dispatch) {
          listener = dispatch
          listeners.push(listener)
          if (shouldPrime) {
            listener(currentValue)
          }
          if (onListeningChange) {
            onListeningChange(listeners.length > 0)
          }
        },
        cancel() {
          listeners = listeners.filter((l) => l !== listener)
          if (onListeningChange) {
            onListeningChange(listeners.length > 0)
          }
        },
      }
    },
  }
}
