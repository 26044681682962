import 'trix'
import 'trix/dist/trix.css'
import React, { RefObject } from 'react'
import { LabeledFieldProps, SandwichLabeledField } from './field'
import styled from 'styled-components'

const RichTextStyles = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;

  trix-toolbar {
    background-color: #fdfdfd;
    border-bottom: 1px solid #ddd;
    padding: 5px;
  }

  .trix-button-group {
    margin-bottom: 0px;
  }

  .trix-button-group:not(:first-child) {
    margin-left: 5px;
  }

  trix-editor {
    border: none;
    border-radius: 0px;
    padding: 10px;
  }
`

type RichTextProps = {
  editorKey: string
  isEnabled: boolean

  value: string
  onValue(html: string): void
  onAttachment?: (attachment: unknown) => void
}

export class RichTextEditor extends React.Component<RichTextProps> {
  editorRef: RefObject<HTMLElement>

  constructor(props: RichTextProps) {
    super(props)

    this.editorRef = React.createRef()
    this.handleChange = this.handleChange.bind(this)
    this.handleAttachmentAdd = this.handleAttachmentAdd.bind(this)
  }

  handleChange(event: Event) {
    const target = event.target
    if (target instanceof Element) {
      this.props.onValue(target.innerHTML)
    }
  }

  handleAttachmentAdd(event: Event) {
    const { onAttachment } = this.props
    if (!onAttachment) {
      event.preventDefault()
      return
    }

    onAttachment((event as any).attachment)
  }

  override componentDidMount() {
    const editor = this.editorRef.current
    if (editor) {
      editor.addEventListener('trix-change', this.handleChange)
      editor.addEventListener('trix-attachment-add', this.handleAttachmentAdd)
    }
  }

  override componentWillUnmount() {
    const editor = this.editorRef.current
    if (editor) {
      editor.removeEventListener('trix-change', this.handleChange)
      editor.removeEventListener(
        'trix-attachment-add',
        this.handleAttachmentAdd
      )
    }
  }

  override render() {
    const { editorKey, value } = this.props
    const editorId = `editor-${editorKey}`
    const inputId = `input-${editorKey}`

    const props = {
      id: editorId,
      ref: this.editorRef,
      input: inputId,
    }

    return (
      <>
        {React.createElement('trix-editor', props)}
        <input
          {...{
            type: 'hidden',
            id: inputId,
            value,
          }}
        />
      </>
    )
  }
}

export function RichTextField(props: RichTextProps & LabeledFieldProps) {
  const { title, description, noLabelWrap, noPadding, ...textProps } = props
  return (
    <SandwichLabeledField
      {...{
        title,
        description,
        isEnabled: props.isEnabled,
        noPadding,
        noLabelWrap,
      }}
    >
      <RichTextStyles>
        <RichTextEditor {...textProps} />
      </RichTextStyles>
    </SandwichLabeledField>
  )
}
