import React from 'react'
import { TextField } from '../../../../views/text-field'

const init = () => ({
  lessThan: null,
  greaterThan: null
})

const extract = (model) =>
  [
    model.lessThan !== null && { op: 'lt', value: model.lessThan },
    model.greaterThan !== null && { op: 'gt', value: model.greaterThan }
  ].filter((x) => x)

const restore = (model, flatFilters) => {
  let lessThan = null
  let greaterThan = null
  flatFilters.forEach((filter) => {
    const num = parseFloat(filter.value)
    if (!isFinite(num)) {
      return
    }

    switch (filter.op) {
      case 'lt':
        lessThan = num
        break
      case 'gt':
        greaterThan = num
        break
      default:
    }
  })

  return { ...model, lessThan, greaterThan }
}

function NumberField ({ value, onValue, ...props }) {
  return (
    <TextField
      {...{
        ...props,
        type: 'number',
        value: value === null ? '' : value,
        onValue (textValue) {
          const num = parseFloat(textValue)
          const value = isFinite(num) ? num : null
          onValue(value)
        }
      }}
    />
  )
}

function view (model, onValue, viewOptions) {
  return (
    <>
      <NumberField
        {...{
          title: viewOptions.lessThanLabel,
          isEnabled: model.isEnabled,
          autoFocus: model.autoFocus,
          value: model.lessThan,
          onValue (lessThan) {
            onValue({
              ...model,
              lessThan
            })
          }
        }}
      />
      <NumberField
        {...{
          title: viewOptions.greaterThanLabel,
          isEnabled: model.isEnabled,
          value: model.greaterThan,
          onValue (greaterThan) {
            onValue({
              ...model,
              greaterThan
            })
          }
        }}
      />
    </>
  )
}

export const program = { init, view, extract, restore }
