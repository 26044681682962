import { ActionWidget, makeProgram as makeActionProgram } from './action-widget'
import { makeListProgram } from './table'
import { makeItemProgram } from './item-widget'
import { absurd } from '../../../util/exhaustiveness'
import { Remote } from '../remote'
import { RouterWithLink } from '../side'
import { Effect, Program } from 'center/compiled/util/raj'
import { ReactNode } from 'react'
import { ToastReporter } from '../toast'
import { RouteEmitter } from '../route'
import {
  RenderedFrame,
  RenderedItem,
  RenderedItemNotFound,
  RenderedList,
} from 'center/src/ui-bridge'
import { makeFrameProgram } from './frame-widget'
import { makeItemNotFoundProgram } from './item-not-found-widget'

export type Widget =
  | ActionWidget
  | RenderedItem
  | RenderedItemNotFound
  | RenderedList
  | RenderedFrame

export type RouterWithLinkAndReload = RouterWithLink & {
  reload: () => Effect<never>
}

type WidgetOptions = {
  widget: Widget
  widgetIndex: number
  routeOptions: Record<string, string>
  remote: Remote
  router: RouterWithLinkAndReload
  routeEmitter: RouteEmitter
  toasts: ToastReporter
}

export function makeWidgetProgram({
  widget,
  widgetIndex,
  routeOptions,
  remote,
  router,
  routeEmitter,
  toasts,
}: WidgetOptions): Program<unknown, unknown, ReactNode> {
  switch (widget.type) {
    case 'action':
      return makeActionProgram({
        widget,
        widgetIndex,
        remote,
        router,
      })
    case 'item':
      return makeItemProgram({
        widget,
        remote,
        router,
        toasts,
      })
    case 'item_not_found':
      return makeItemNotFoundProgram({ widget })

    case 'list':
      return makeListProgram({
        widget,
        routeOptions,
        remote,
        router,
        routeEmitter,
        toasts,
      })
    case 'frame':
      return makeFrameProgram(widget)
    default:
      absurd(widget)
  }
}
