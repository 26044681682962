import { makeRoutedProgram } from '../../util/raj-spa'
import { createEmitter, Emitter } from '../../util/emitter'
import { makeProgram as makeCheckProgram } from './check'
import { makeRemote, makeFrontProgram, makeSideProgram } from '../front'
import { batchPrograms } from 'center/compiled/util/raj-compose'
import { CenterLayout, MainSideLayout } from '../../views/structure'
import { Remote } from '../front/remote'
import { ControlledRouterWithLink } from '../front/side'
import { HttpClient } from '../../util/http'
import { absurd } from '../../util/exhaustiveness'
import { ReactNode } from 'react'
import { makeEffectHttpClient } from '.'

const singleLayout = (remote: Remote, router: ControlledRouterWithLink) => {
  const mainProgram = makeFrontProgram(remote, router)
  return batchPrograms([mainProgram], ([main]) => (
    <CenterLayout>{main!()}</CenterLayout>
  ))
}

const splitLayout = (remote: Remote, router: ControlledRouterWithLink) => {
  const sideProgram = makeSideProgram(remote, router)
  const mainProgram = makeFrontProgram(remote, router)
  return batchPrograms([sideProgram, mainProgram], ([side, main]) => (
    <MainSideLayout
      {...{
        side: side!(),
        main: main!(),
      }}
    />
  ))
}

// // TODO: maybe specify layout on the back-end spec
// const pathBuilders = {
//   '/account/gateway/front': splitLayout,
//   '/account/account/front': splitLayout,
// }

export type FlowData = { path: string; layout: 'single' | 'main-side' }

function makeFlowFront(
  flowData: FlowData,
  httpClient: HttpClient,
  router: ControlledRouterWithLink
) {
  const remote = makeRemote(httpClient, flowData.path)

  let builder
  switch (flowData.layout) {
    case 'single':
      builder = singleLayout
      break
    case 'main-side':
      builder = splitLayout
      break
    default:
      absurd(flowData.layout)
  }

  return builder(remote, router)
}

export function makeAuthenticationProgram(
  authEmitter: Emitter<void>,
  httpClient: HttpClient,
  router: ControlledRouterWithLink
) {
  const stepEmitter = createEmitter<FlowData>({
    shouldPrime: false,
  })

  const fireCompletion = (data: FlowData) => {
    if (!data.path) {
      return authEmitter.emit()
    }
    return stepEmitter.emit(data)
  }

  const effectHttpClient = makeEffectHttpClient(httpClient)

  return makeRoutedProgram<FlowData, ReactNode>({
    router: stepEmitter,
    initialProgram: makeCheckProgram(effectHttpClient, fireCompletion),
    getRouteProgram: (flowData) => makeFlowFront(flowData, httpClient, router),
  })
}

export function makeSwitcherProgram(
  httpClient: HttpClient,
  router: ControlledRouterWithLink
) {
  return makeFlowFront(
    { path: '/account/account/front', layout: 'single' },
    httpClient,
    router
  )
}
