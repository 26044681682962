import { batchPrograms } from 'center/compiled/util/raj-compose'
import {
  makeAuthProgram as makeAuthenticationProgram,
  RouterSegment,
} from './authorize'
import { GlobalStyle } from '../views/global'
import { CenterLayout, MainSideLayout } from '../views/structure'
import { createHashChangeRouter } from '../util/hash-router'
import { makeRemote, makeFrontProgram } from './front'
import { HttpClient } from '../util/http'
import { ControlledRouterWithLink, makeSideProgram } from './front/side'
import { createEmitter } from '../util/emitter'
import { makeRoutedProgram } from '../util/raj-spa'
import { ResponseUIData } from 'center/src/ui-bridge'
import { ReactNode } from 'react'
import { absurd } from '../util/exhaustiveness'

const router: ControlledRouterWithLink = {
  ...createHashChangeRouter(true),
  link: (href) => `#${href}`,
}

const accountSegment = '/settings'
const routerSegment: RouterSegment = {
  homeRoute: '#/',
  parseRoute(route) {
    if (route.startsWith(accountSegment)) {
      return route.slice(accountSegment.length)
    }

    return route // TODO: unsure if this is right
  },
  buildRoute(token) {
    return `#${accountSegment}${token}`
  },
}

export function makeProgram(httpClient: HttpClient) {
  return makeAuthenticationProgram({
    httpClient,
    router,
    routerSegment,
    makeAppProgram: (router, makeSideProgram) => {
      const remote = makeRemote(httpClient, '/front')

      return batchPrograms(
        [makeSideProgram(remote, router), makeFrontProgram(remote, router)],
        ([side, body]) => (
          <>
            <GlobalStyle />
            <MainSideLayout
              {...{
                side: side!(),
                main: body!(),
              }}
            />
          </>
        )
      )
    },
  })
}

export function makeAdaptiveProgram(
  httpClient: HttpClient,
  remotePath: `/${string}`
) {
  const uiEmitter = createEmitter<ResponseUIData>({
    initialValue: { key: 'init', layout: 'single' },
    shouldPrime: true,
  })

  const remote = makeRemote(httpClient, remotePath, (uiData) =>
    uiEmitter.emit(uiData)
  )

  return makeRoutedProgram({
    router: uiEmitter,
    initialProgram: {
      init: [undefined],
      update: () => [undefined],
      view: () => (<></>) as ReactNode,
    },
    getRouteProgram(uiData, { keyed }) {
      return keyed(uiData.key, () => {
        switch (uiData.layout) {
          case 'main-side':
            return batchPrograms(
              [
                makeFrontProgram(remote, router),
                makeSideProgram(remote, router),
              ],
              ([main, side]) => (
                <>
                  <GlobalStyle />
                  <MainSideLayout
                    {...{
                      side: side!(),
                      main: main!(),
                    }}
                  />
                </>
              )
            )
          case 'single':
            return batchPrograms(
              [makeFrontProgram(remote, router)],
              ([main]) => (
                <>
                  <GlobalStyle />
                  <CenterLayout>{main!()}</CenterLayout>
                </>
              )
            )
          default:
            absurd(uiData.layout)
        }
      })
    },
  })
}
