import { ReactNode } from 'react'
import { reactProgram } from '../../../util/raj-react'
import {
  Paragraph,
  Code,
  Card,
  CardSection,
  CardHeader,
} from '../../../views/card'
import { CopyField } from '../../../views/copy-field'
import { ModalOverlay } from '../../../views/overlay'
import { mapEffect } from 'center/compiled/util/raj-compose'
import { Change, Dispatch } from 'center/compiled/util/raj'
import { absurd } from '../../../util/exhaustiveness'
import { Remote } from '../remote'

type Msg =
  | { type: 'show_secret' }
  | { type: 'hide_secret' }
  | {
      type: 'loaded_secret'
      result:
        | { type: 'error'; message: string }
        | { type: 'value'; secret: string }
    }

type Value = { id: string; state: string; preview: string }

type Model = {
  value: Value
  isLoading: boolean
  loadError: { message: string } | undefined
  secret: string | undefined
}

type SecretProgramOptions = {
  value: Value
  remote: Remote
  viewOptions: {
    title: string
    description: string | undefined
    isMultipleLines: boolean
  }
}

function makeProgram({ value, remote, viewOptions }: SecretProgramOptions) {
  const init: Change<Msg, Model> = [
    {
      value,
      isLoading: false,
      loadError: undefined,
      secret: undefined,
    },
  ]

  const update = (msg: Msg, model: Model): Change<Msg, Model> => {
    switch (msg.type) {
      case 'show_secret': {
        return [
          { ...model, isLoading: true },
          mapEffect(
            remote.loadEffect({
              id: value.id,
              action: 'retrieve',
              params: { secretState: value.state },
            }),
            (data) =>
              ({
                type: 'loaded_secret',
                result: data,
              } as Msg)
          ),
        ]
      }
      case 'hide_secret': {
        return [{ ...model, secret: undefined }]
      }
      case 'loaded_secret': {
        const newModel = { ...model, isLoading: false, loadError: undefined }
        const { result } = msg
        switch (result.type) {
          case 'error': {
            return [{ ...newModel, loadError: { message: result.message } }]
          }
          case 'value': {
            return [{ ...newModel, secret: result.secret }]
          }
          default:
            absurd(result)
        }
      }
    }
  }

  const view = (model: Model, dispatch: Dispatch<Msg>): ReactNode => {
    return (
      <>
        <Paragraph
          onClick={() => !model.isLoading && dispatch({ type: 'show_secret' })}
          style={{ cursor: 'pointer' }}
        >
          <Code>{model.value.preview}</Code>
        </Paragraph>
        {model.secret && (
          <ModalOverlay onDismiss={() => dispatch({ type: 'hide_secret' })}>
            <Card>
              <CardHeader title={viewOptions.title} />
              <CardSection>
                <CopyField
                  {...{
                    title: '', // TODO: is this right?
                    description: viewOptions.description,
                    isMultipleLines: viewOptions.isMultipleLines,
                    isSecret: true,
                    isEnabled: true,
                    value: model.secret,
                  }}
                />
              </CardSection>
            </Card>
          </ModalOverlay>
        )}
      </>
    )
  }

  return { init, update, view }
}

export const Secret = reactProgram((props: SecretProgramOptions) =>
  makeProgram(props)
)
