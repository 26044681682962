import { InternalError, ResponseUIData } from 'center/src/ui-bridge'
import { HttpClient } from '../../util/http'
import { Dispatch, Effect } from 'center/compiled/util/raj'

type Request = {
  id: string
  url?: string
  action: string
  params?: Record<string, unknown>
}
type Response = Record<string, unknown>

type Req<I extends Record<string, unknown>> = {
  payload: I
}

export type RemoteResult<V> =
  | { type: 'network_error'; message: string }
  | { type: 'internal_error' }
  | { type: 'reply'; reply: V }

export type Remote = {
  loadAsync(req: Request): Promise<Response>
  loadEffect(req: Request): Effect<Response>

  loadAsync2<In extends Record<string, unknown>, Out>(
    req: Req<In>
  ): Promise<Out | InternalError>

  loadEffect2<In extends Record<string, unknown>, Out>(
    req: Req<In>
  ): Effect<RemoteResult<Out>>
}

function isJsonObject(value: unknown): value is Record<string, unknown> {
  return !!(value && typeof value === 'object')
}

export function makeRemote(
  httpClient: HttpClient,
  frontPath: string,
  onResponseUIData?: (uiData: ResponseUIData) => void
): Remote {
  let csrfToken: string | undefined

  async function loadAsync({ id, url, action, params }: Request) {
    const json = await httpClient.post(frontPath, {
      id,
      url,
      action,
      params,
      csrfToken,
    })

    if (!isJsonObject(json)) {
      throw new Error('expected json object')
    }

    const responseCsrfToken = json['csrfToken']
    if (responseCsrfToken && typeof responseCsrfToken === 'string') {
      csrfToken = responseCsrfToken
    }

    return json
  }

  // TODO: Add error boundaries to programs to
  //   short-circuit errors bubbling up dispatch.
  function ignoreDownstream(fn: () => void) {
    try {
      fn()
    } catch (error) {
      console.error(error)
    }
  }

  function loadEffect(options: Request) {
    return (dispatch: Dispatch<Response>) => {
      loadAsync(options)
        .then((json) => {
          ignoreDownstream(() => dispatch(json))
        })
        .catch((error) => {
          ignoreDownstream(() => dispatch({ type: 'error', data: error }))
        })
    }
  }

  async function loadAsync2<In extends Record<string, unknown>, Out>({
    payload,
  }: Req<In>): Promise<Out> {
    const json = await httpClient.post(frontPath, {
      payload,
      csrfToken,
    })

    if (!isJsonObject(json)) {
      throw new Error('expected json object')
    }

    const uiData = json['ui']
    if (uiData && onResponseUIData) {
      onResponseUIData(uiData as any)
    }

    const responseCsrfToken = json['csrfToken']
    if (responseCsrfToken && typeof responseCsrfToken === 'string') {
      csrfToken = responseCsrfToken
    }

    return json['reply'] as any
  }

  function loadEffect2<In extends Record<string, unknown>, Out>(
    req: Req<In>
  ): Effect<RemoteResult<Out>> {
    return (dispatch: Dispatch<RemoteResult<Out>>) => {
      loadAsync2(req)
        .then((json) => {
          ignoreDownstream(() => {
            const res = json as any
            if (res && res.type === 'error') {
              dispatch({ type: 'internal_error' })
              return
            }

            dispatch({ type: 'reply', reply: json as any })
          })
        })
        .catch((error) => {
          ignoreDownstream(() =>
            dispatch({ type: 'network_error', message: error.message })
          )
        })
    }
  }

  return {
    loadAsync,
    loadEffect,

    loadAsync2,
    loadEffect2,
  }
}
