import styled, { css } from 'styled-components'
import { Pad, Split, SplitBlock, SplitPriority } from './spacing'

const colorCss = {
  gray: css`
    background-color: #eee;
    color: #222;
  `,
  red: css`
    background-color: #ffcccc;
    color: #420c0f;
  `,
  blue: css`
    background-color: #def;
    color: #223;
  `,
}

type Color = keyof typeof colorCss

const BannerInner = styled.div<{ color: Color }>`
  border-radius: 3px;
  padding: 10px;

  ${({ color }) => colorCss[color]}
`

const BannerTitle = styled.h4`
  font-size: 1em;
  margin: 0px;
`

const BannerDescription = styled.p`
  font-size: 1em;
  margin: 0px;
`

const BannerClose = styled.div`
  font-size: 1.25em;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);

  &:hover {
    color: #000;
  }
`

type BannerProps = {
  color: Color
  title: string
  description?: string
  onDismiss?: () => void
}

export function Banner({ color, title, description, onDismiss }: BannerProps) {
  return (
    <Pad>
      <BannerInner color={color}>
        <Split verticalCenter={!description}>
          <SplitPriority>
            <BannerTitle>{title}</BannerTitle>
            <BannerDescription>{description}</BannerDescription>
          </SplitPriority>
          {onDismiss && (
            <>
              <SplitBlock />
              <BannerClose onClick={() => onDismiss()}>✕</BannerClose>
            </>
          )}
        </Split>
      </BannerInner>
    </Pad>
  )
}

export function ErrorBanner({
  errorMessage,
  ...props
}: BannerProps & { errorMessage: string }) {
  return (
    <Banner
      {...{
        ...props,
        color: 'red',
        description: errorMessage,
      }}
    />
  )
}
