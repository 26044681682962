import ReactDom from 'react-dom'
import { reactProgram } from '../util/raj-react'
import { makeAdaptiveProgram } from '../programs/dashboard'
// import debug from 'raj-web-debugger'
import { makeHTTPClient } from '../util/http'

const apiURL = process.env['REACT_APP_API_URL']!
const httpClient = makeHTTPClient(apiURL)

const containerElement = document.getElementById('root')

let appProgram = makeAdaptiveProgram(httpClient, '/ui')

// if (process.env.NODE_ENV !== 'production') {
//   appProgram = debug(appProgram)
// }

const Application = reactProgram(() => appProgram)

ReactDom.render(<Application />, containerElement)
