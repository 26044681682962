import { RenderedFrame } from 'center/src/ui-bridge'
import { ReactNode, useEffect, useRef } from 'react'
import { Program } from 'center/compiled/util/raj'
import { Card, CardSection, Heading } from '../../../views/card'

function DangerouslySetHtmlContent({ html }: { html: string }) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const fragment = document.createRange().createContextualFragment(html)
      ref.current.innerHTML = ''
      ref.current.appendChild(fragment)
    }
  }, [html, ref])

  return <div ref={ref}></div>
}

export function makeFrameProgram(
  frame: RenderedFrame
): Program<unknown, unknown, ReactNode> {
  return {
    init: [undefined],
    update() {
      return [undefined]
    },
    view() {
      return (
        <Card>
          <CardSection>
            <Heading>{frame.title}</Heading>
          </CardSection>
          <CardSection>
            <DangerouslySetHtmlContent {...{ html: frame.html }} />
          </CardSection>
        </Card>
      )
    },
  }
}
