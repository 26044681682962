import { makeRoutedProgram } from '../../../util/raj-spa'
import { batchPrograms, batchEffects } from 'center/compiled/util/raj-compose'
import { createEmitter } from '../../../util/emitter'
import { ModalOverlay } from '../../../views/overlay'
import { makeActionProgram } from './action'
import { ReactNode } from 'react'
import { Program } from 'center/compiled/util/raj'
import { Remote } from '../remote'
import { ActionWidget } from './action-widget'
import { RouterWithLinkAndReload } from '.'
import { ToastReporter } from '../toast'

type CommonState = {
  action: { key: string } | undefined
  selection: string[]
  lastSelectedItem: string | undefined
}

export type MainProvidedOptions = {
  selection: string[]
  lastSelectedItem: string | undefined
  updateState: (state: CommonState) => void
}

export type ActionModelOverlayOptions = {
  makeProgram: (
    mpOptions: MainProvidedOptions
  ) => Program<unknown, unknown, ReactNode>
  remote: Remote
  router: RouterWithLinkAndReload
  toasts: ToastReporter
}

export function withActionModalOverlay({
  makeProgram: makeMainProgram,
  remote,
  router,
  toasts,
}: ActionModelOverlayOptions) {
  const commonStateEmitter = createEmitter<CommonState>({
    shouldPrime: true,
    initialValue: {
      action: undefined,
      selection: [],
      lastSelectedItem: undefined,
    },
  })

  const makeInnerProgram = (
    selection: string[],
    lastSelectedItem: string | undefined
  ) =>
    makeMainProgram({
      selection,
      lastSelectedItem,
      updateState: ({ action, selection, lastSelectedItem }) =>
        commonStateEmitter.emit({ action, selection, lastSelectedItem }),
    })

  const initialProgram = makeInnerProgram([], undefined)

  return makeRoutedProgram({
    initialProgram,
    router: commonStateEmitter,
    async getRouteProgram(state) {
      const { action, selection, lastSelectedItem } = state
      const mainProgram = makeInnerProgram(selection, lastSelectedItem)

      if (!action) {
        return mainProgram
      }

      const actionData = await remote.loadAsync2<
        { type: 'action_render'; actionKey: string },
        ActionWidget | { type: 'action_not_found' }
      >({
        payload: {
          type: 'action_render',
          actionKey: action.key,
          // params: { selection },
        },
      })

      if (actionData.type === 'action_not_found') {
        return mainProgram
      }

      if (actionData.type === 'error') {
        return mainProgram
      }

      const dismissAction = () => () =>
        commonStateEmitter.emit({ ...state, action: undefined })

      return batchPrograms(
        [
          mainProgram,
          makeActionProgram({
            action: actionData,
            selection,
            autoFocusFirstInput: true,
            remote: remote,
            router: router,
            dismissed: dismissAction,
            completed: (completedMessage) => {
              const reloadEffect = router.reload()
              if (!completedMessage) {
                return reloadEffect
              }

              const toastEffect = toasts.showToast({
                key: action.key,
                message: completedMessage,
              })

              return batchEffects([toastEffect, reloadEffect])
            },
          }),
        ],
        ([widget, action]) => (
          <>
            {widget!()}
            <ModalOverlay onDismiss={() => dismissAction()()}>
              {action!()}
            </ModalOverlay>
          </>
        )
      )
    },
  })
}
