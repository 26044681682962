import { CardSection, Card, Heading, Attributes } from '../../../views/card'
import { Button, ButtonSet } from '../../../views/button'
import { MainProvidedOptions, withActionModalOverlay } from './action-overlay'
import { Optional } from './table'
import { absurd } from '../../../util/exhaustiveness'
import { Change, Dispatch } from 'center/compiled/util/raj'
import { RouterWithLink } from '../side'
import { Remote } from '../remote'
import { RouterWithLinkAndReload } from '.'
import { ToastReporter } from '../toast'
import { RenderedItem } from 'center/src/ui-bridge'
import { makeFieldView } from './field'

type FullProgramOptions = {
  widget: RenderedItem
  remote: Remote
  router: RouterWithLinkAndReload
  toasts: ToastReporter
}

export function makeItemProgram({
  widget,
  remote,
  router,
  toasts,
}: FullProgramOptions) {
  return withActionModalOverlay({
    remote,
    router,
    toasts,
    makeProgram: ({ updateState }) =>
      makeObjectProgram({
        widget,
        remote,
        router,
        updateState,
      }),
  })
}

type ObjectProgramOptions = {
  widget: RenderedItem
  remote: Remote
  router: RouterWithLink
  updateState: MainProvidedOptions['updateState']
}

function makeObjectProgram({
  widget,
  remote,
  router,
  updateState,
}: ObjectProgramOptions) {
  const { title, actions, fields, fieldValues: object } = widget

  const init: Change<Msg, Model> = [
    {
      actions,
      object,
    },
  ]

  function update(msg: Msg, model: Model): Change<Msg, Model> {
    switch (msg.type) {
      case 'update_state': {
        const action = model.actions.find(
          (action) => action.key === msg.actionId
        )

        if (!action) {
          return [model]
        }

        const selection = widget.selectionKey ? [widget.selectionKey] : []

        return [
          model,
          () =>
            updateState({
              action: { key: action.key },
              selection: selection,
              lastSelectedItem: widget.selectionKey,
            }),
        ]
      }
      default:
        return absurd(msg.type)
    }
  }

  function view(model: Model, dispatch: Dispatch<Msg>) {
    return viewWithOptions(model, dispatch, {
      title,
      fields,
      makeLocalLink: router.link,
      remote: remote,
    })
  }

  return { init, update, view }
}

type Action = { key: string; buttonText: string }

type Model = {
  actions: Action[]
  object: Record<string, unknown>
}

type Msg = { type: 'update_state'; actionId: string }

type ViewOptions = {
  title: string
  fields: RenderedItem['fields']
  makeLocalLink: (href: string) => string
  remote: Remote
}

function viewWithOptions(
  model: Model,
  dispatch: Dispatch<Msg>,
  viewOptions: ViewOptions
) {
  const { object, actions } = model
  const { fields, title, makeLocalLink, remote } = viewOptions

  return (
    <Card>
      <CardSection>
        <Heading>{title}</Heading>
      </CardSection>
      <Attributes
        {...{
          attributes: fields.map((field) => ({
            key: field.key,
            label: field.title,
            value:
              field.optional && object[field.key] === undefined ? (
                <Optional title={field.optionalText} />
              ) : (
                makeFieldView({
                  value: { ...(object[field.key] as any) },
                  field,
                  makeLocalLink,
                  remote,
                })
              ),
          })),
        }}
      />
      {actions.length > 0 && (
        <CardSection>
          <ButtonSet>
            {actions.map((action) => (
              <Button
                key={action.key}
                {...{
                  title: action.buttonText,
                  isEnabled: true,
                  onClick() {
                    dispatch({ type: 'update_state', actionId: action.key })
                  },
                }}
              />
            ))}
          </ButtonSet>
        </CardSection>
      )}
    </Card>
  )
}
