import styled, { css } from 'styled-components'
import { ActionLink, Menu } from '../../views/structure'
import { Cover, LoadingIndicator, ErrorIndicator } from '../../views/indicator'
import { Remote, RemoteResult } from './remote'
import { Effect, Program } from 'center/compiled/util/raj'
import { mapEffect } from 'center/compiled/util/raj-compose'
import { mapSubscription, withSubscriptions } from '../../util/raj-subscription'
import { absurd } from '../../util/exhaustiveness'
import { ReactNode } from 'react'
import { ControlledRouter, Router } from '../../util/raj-spa'
import {
  NavigationGroup,
  NavigationItem,
  NavigationRenderPayload,
  RenderedNavigation,
} from 'center/src/ui-bridge'

const Navigation = styled.nav``

const NavigationSection = styled.div`
  margin: 0px 0px 15px 0px;
`

const NavigationSectionLabel = styled.b`
  font-size: 0.8em;
  font-weight: normal;
  color: #333;
  padding: 6px 15px;
`

const NavigationList = styled.ul<{ indent?: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.indent &&
    css`
      margin-left: 10px;
    `}
`

const NavigationListItem = styled.li``

const NavigationLink = styled.a<{ isActive?: boolean }>`
  display: block;
  text-decoration: none;
  color: #333;
  padding: 6px 18px 6px 15px;
  margin: 0px;
  font-size: 1em;

  &:hover {
    color: #000;
    text-decoration: underline;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: #000;
      font-weight: bold;
      text-decoration: none;
    `}
`

type Msg =
  | {
      type: 'loaded'
      data: RemoteResult<RenderedNavigation>
    }
  | { type: 'set_route'; route: string }

type Model = {
  loadError?: { message: string }
  navigation?: RenderedNavigation
  currentRoute: string | undefined
}

export type RouterWithLink = Router<string> & {
  link(href: string): string
}

export type ControlledRouterWithLink = ControlledRouter<string> & {
  link: (href: string) => string
}

export function makeSideProgram(
  remote: Remote,
  router: RouterWithLink,
  actionLink?: ActionLink
): Program<Msg, unknown, ReactNode> {
  const init: [Model, Effect<Msg>] = [
    {
      currentRoute: undefined,
    },
    mapEffect(
      remote.loadEffect2<NavigationRenderPayload, RenderedNavigation>({
        payload: { type: 'navigation_render' },
      }),
      (data) => ({ type: 'loaded', data: data })
    ),
  ]

  return withSubscriptions({
    init,
    update(msg, model) {
      switch (msg.type) {
        case 'loaded': {
          const newModel = { ...model, loadError: undefined }
          const result = msg.data
          switch (result.type) {
            case 'reply':
              return [{ ...newModel, navigation: result.reply }]
            case 'internal_error':
              return [
                {
                  ...newModel,
                  loadError: {
                    message:
                      'Failed to load sidebar due to an unexpected error.',
                  },
                },
              ]
            case 'network_error':
              return [{ ...newModel, loadError: { message: result.message } }]
            default:
              return absurd(result)
          }
        }
        case 'set_route': {
          return [{ ...model, currentRoute: msg.route }]
        }
        default:
          absurd(msg)
      }
    },
    subscriptions: () => ({
      route: () =>
        mapSubscription(router.subscribe(), (route) => ({
          type: 'set_route' as const,
          route,
        })),
    }),
    view(model: Model) {
      if (model.loadError) {
        return (
          <Cover>
            <ErrorIndicator
              {...{
                failure: 'Failed to load',
                message: model.loadError.message,
              }}
            />
          </Cover>
        )
      }

      if (!model.navigation) {
        return (
          <Cover>
            <LoadingIndicator />
          </Cover>
        )
      }

      const { header, navigation } = model.navigation

      return (
        <>
          {header && renderHeader(header, actionLink)}
          <Navigation>
            <NavigationList>
              {navigation.map((navPart) =>
                renderNavPart(navPart, model.currentRoute, router.link)
              )}
            </NavigationList>
          </Navigation>
        </>
      )
    },
  })
}

function renderNavPart(
  navPart: NavigationItem | NavigationGroup,
  currentRoute: string | undefined,
  link: RouterWithLink['link']
) {
  switch (navPart.type) {
    case 'nav_item':
      return renderNavItem(navPart, currentRoute, link)
    case 'nav_group':
      return (
        <NavigationSection key={navPart.key}>
          {navPart.title && (
            <NavigationSectionLabel>{navPart.title}</NavigationSectionLabel>
          )}
          <NavigationList>
            {navPart.items.map((item) =>
              renderNavItem(item, currentRoute, link)
            )}
          </NavigationList>
        </NavigationSection>
      )
    default:
      absurd(navPart)
  }
}

function renderHeader(
  header: RenderedNavigation['header'],
  actionLink?: ActionLink
) {
  const { title, description } = header

  return (
    <Menu
      {...{
        title,
        description,
        actionLink,
      }}
    />
  )
}

function doesNavItemMatchUrlPath(
  navItem: NavigationItem,
  currentPath: string
): boolean {
  return navItem.urlPath === currentPath
}

function doesNavItemContainUrlPath(
  navItem: NavigationItem,
  currentPath: string
): boolean {
  return navItem.items.some((navPart) => {
    switch (navPart.type) {
      case 'nav_item':
        if (doesNavItemMatchUrlPath(navPart, currentPath)) {
          return true
        }

        if (!navPart.items.length) {
          return false
        }

        return doesNavItemContainUrlPath(navPart, currentPath)
      case 'nav_group':
        return false
      default:
        return absurd(navPart)
    }
  })
}

function renderNavItem(
  navItem: NavigationItem,
  currentRoute: string | undefined,
  link: RouterWithLink['link']
) {
  const { key, title, urlPath, items } = navItem
  const [matches, contains] = currentRoute
    ? [
        doesNavItemMatchUrlPath(navItem, currentRoute),
        doesNavItemContainUrlPath(navItem, currentRoute),
      ]
    : [false, false]
  const showNestedItems = matches || contains

  return (
    <NavigationListItem key={key}>
      <NavigationLink
        {...{
          href: link(urlPath),
          isActive: matches,
          children: title,
        }}
      />
      {showNestedItems && (
        <NavigationList indent>
          {items.map((item) => renderNavPart(item, currentRoute, link))}
        </NavigationList>
      )}
    </NavigationListItem>
  )
}
