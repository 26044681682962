import { ReactNode } from 'react'
import { Program } from 'center/compiled/util/raj'

export function viewOnlyProgram(
  view: () => ReactNode
): Program<void, void, ReactNode> {
  return {
    init: [void 0],
    update: () => [void 0],
    view,
  }
}
